import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Collapse,
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    Hidden,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';

import {
    AlertCircle as AlertCircleIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    User as UserIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Activity,
} from 'react-feather';
import MemoryIcon from '@material-ui/icons/Memory';
import NavItem from './NavItem';
import { UserContext } from 'src/providers/UserProvider';
import actionsList from 'src/config/actionsConfig';


const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const marvinUser = React.useContext(UserContext);
    const user = {
        avatar: (marvinUser ? marvinUser.name.charAt(0).toUpperCase() : '?'),
        jobTitle: (marvinUser ? marvinUser.role : '?'),
        name: (marvinUser ? marvinUser.name : '')
    };


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);


    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <TextField label="Search Action"></TextField>
            </Box>
            <Divider />
            <Box p={2}>
                <List
                    disablePadding
                    component="nav"
                    subheader={
                        <ListSubheader component="div" id="action-menu">
                            Action Menu
                        </ListSubheader>
                    }
                >
                    {actionsList.map((item) => (<NavItem module={ item } />))}
                </List>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default NavBar;
