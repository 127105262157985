import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    colors,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import Script from './Script';
import Status from './Status';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatarIcon: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56
    },
    redIcon: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56
    },
    greenIcon: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56
    },
    differenceIcon: {
        color: colors.red[900]
    },
}));

const Header = ({ className, execution, ...rest }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={1}
        >
            <Grid 
                item
                xl={8}
                lg={8}
                sm={8}
                xs={12}
            >
                <Script scriptName={execution.scriptName}/>
            </Grid>
            <Grid 
                item
                xl={4}
                lg={4}
                sm={4}
                xs={12}
            >
                <Status status={execution.execStatus}/>
            </Grid>
        </Grid>
    );
}

export default Header;