import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyAIVB8GZm3hOSxDVs1f92GlkPJcyvFhzJI",
    authDomain: "marvin-a006f.firebaseapp.com",
    databaseURL: "https://marvin-a006f.firebaseio.com",
    projectId: "marvin-a006f",
    storageBucket: "marvin-a006f.appspot.com",
    messagingSenderId: "1072999659112",
    appId: "1:1072999659112:web:fa91c601e266538c5eaddd",
    measurementId: "G-7TYYPLSCM8"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth();
export default firebase;
