import React, { useState } from 'react';
import {
    makeStyles,
    colors,
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { PropTypes } from 'prop-types';


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[600]
    },
    greenIcon: {
        color: colors.green[600]
    },
    orangeIcon: {
        color: colors.orange[900],
    },
    blueIcon: {
        color: colors.indigo[900],
    },
}));


const Results = ({ className, status, ...rest }) => {
    const classes = useStyles();
    
    let statusIcon = <ErrorRoundedIcon className={classes.redIcon} />

    if (status == 'PENDING') {
        statusIcon = <TrackChangesIcon className={classes.orangeIcon} />
    }
    else if (status == 'SUCCESS') {
        statusIcon = <CheckCircleRoundedIcon className={classes.greenIcon} />
    }

    return (statusIcon);
}

Results.prototype = {
    className: PropTypes.string,
    status: PropTypes.string.isRequired,
}
export default Results;