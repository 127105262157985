import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import LogList from './LogList';
import MailList from './MailList';
import CONFIG from 'src/config/config';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ScriptExecutionDetail = () => {
    const classes = useStyles();
    const [execution, setExecution] = useState({});
    const {executionId} = useParams();
    const storageUser = JSON.parse(localStorage.getItem('marvinUser'));
    const [marvinUser, setMarvinUser] = React.useState(storageUser);


    const getLog = (logedUser, executionId) => {
        console.log('updating execution');
        let result = {};
        try {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", CONFIG.API_URL + '/scripts/execution/' + executionId, false); // False = Sync
            xmlHttp.setRequestHeader('Content-Type', 'application/json');
            xmlHttp.setRequestHeader('Authorization', logedUser.token);
            xmlHttp.setRequestHeader('Access-Control-Allow-Origin', '*')
            xmlHttp.send();
    
            if (xmlHttp.status == 200) {
                result = JSON.parse(xmlHttp.response);
                console.log('execution updated');
            }
            else {
                console.error('Error updating execution => ' + xmlHttp.status);
            }
        }
        catch(error) {
            console.error('[ERROR] Error updating data from server');
            console.error(error);
        }
        return result;
    }


    React.useEffect(() => {
        let data = getLog(marvinUser, executionId);
        setExecution(data);
    }, [])


    if (!execution.logs) {
        execution.logs = [];
    }
    if (!execution.to) {
        execution.to = [];
    }


    return (
        <Page
            className={classes.root}
            title="Activity Detail"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                    >
                        Script Execution: {execution.scriptName} [{execution.id}]
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Header execution={execution}/>
                </Box>
                <Box mt={3}>
                    <LogList log={execution.logs}/>
                </Box>
                <Box mt={3}>
                    <MailList execution={execution}/>
                </Box>
            </Container>
        </Page>
    );
}

export default ScriptExecutionDetail;