import React, { useState, setValues } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    InputAdornment,
    SvgIcon,
    TextField,
    makeStyles,
    Divider,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import ActionScriptExecution from './ActionScriptExecution';
import ActionTerminate from './ActionTerminate';
import BaseAction from './BaseAction';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
}));


const RobotInfo = ({ className, robot, ...rest }) => {
    const classes = useStyles();
    const [find, setFind] = useState("");
    const [modal, setModal] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const actions = [
        {
            id: "ActionScriptExecution",
            title: "Script Execution",
            description: "Execute the script on robot",
        },
        {
            id: "ActionTerminate",
            title: "Terminate Marvin",
            description: "Finishes Marvin execution. This action could not be undone remotely",
        },
        {
            id: "ActionUpdateInfo",
            title: "Update info",
            description: "Updates robot information",
        },
    ]

    const handleChange = (event) => {
        setFind(event.target.value)
    }

    const handleActionClick = (event, id) => {
        setModal(id);
        setModalOpen(true);

    }
    const handleModalClose = (event) => {
        setModalOpen(false);

    }

    return (
        <Box mt={3}>
            {/* <Card
                className={clsx(classes.root, className)}
            >
                <CardHeader
                    title="Action List"
                    subheader="All actions you can perform on your Marvin robot"
                />
                <Divider />
                <CardContent>
                    <Box>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search action"
                            variant="outlined"
                            onChange={handleChange}
                            value={find}
                        />
                    </Box>
                </CardContent>
            </Card> */}
            <Box mt={3}>
                <Grid
                    container
                    spacing={3}
                >
                    {actions.map((action) => (
                        <Grid 
                            item
                            xs={12}
                            sm={6}
                            md={3}
                        >
                            <BaseAction
                                action={action.id}
                                title={action.title}
                                description={action.description}
                                onClick={handleActionClick}
                            >
                            </BaseAction>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box>
                <ActionScriptExecution 
                    opens={modalOpen && modal == 'ActionScriptExecution'} 
                    robot={robot} 
                    onClose={handleModalClose} 
                />
                <ActionTerminate 
                    opens={modalOpen && modal == 'ActionTerminate'} 
                    robot={robot} 
                    onClose={handleModalClose} 
                />
            </Box>
        </Box>
    );
}

export default RobotInfo;