import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    InputLabel,
    Link,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { auth } from 'src/firebase';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const LoginView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = React.useState("")
    const [successMessage, setSuccessMessage] = React.useState("");
    const [waitOpen, setWaitOpen] = React.useState(false);

    const resetPassword = (email) => {
        let validation = Yup.string().email('Must be a valid email');
        let result = validation.isValidSync(email);
        setErrorMessage("");
        setSuccessMessage("");
        setWaitOpen(true);

        if (!result) {
            setErrorMessage('Provide a valid e-mail address to reset password');
            setWaitOpen(false);
        }
        else {
            auth.sendPasswordResetEmail(email).then(function() {
                setSuccessMessage("Check your e-mail for password reset instructions");
                setWaitOpen(false);
            }).catch(function(error) {
                setSuccessMessage("Check your e-mail for password reset instructions");
                setWaitOpen(false);
            });
        }
    };

    const signInWithEmailAndPasswordHandler = (email, password) => {
        setWaitOpen(true);
        auth.signInWithEmailAndPassword(email, password).catch(error => {
            console.error("Error signing in with password and email", error);
            setErrorMessage(error.message);
            setWaitOpen(false);
        });
    };

    return (
        <Page
            className={classes.root}
            title="Login"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={(values, actions) => {
                            setErrorMessage("");
                            setSuccessMessage("");
                            signInWithEmailAndPasswordHandler(values.email, values.password);
                            navigate('/app/dashboard', { replace: true });
                            actions.setSubmitting(false);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box mb={3}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h2"
                                        >
                                            Sign in
                                        </Typography>
                                    </Box>
                                    {/* <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Button
                                                color="primary"
                                                fullWidth
                                                startIcon={<FacebookIcon />}
                                                onClick={handleSubmit}
                                                size="large"
                                                variant="contained"
                                            >
                                                Login with Facebook
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Button
                                                fullWidth
                                                startIcon={<GoogleIcon />}
                                                onClick={handleSubmit}
                                                size="large"
                                                variant="contained"
                                            >
                                                Login with Google
                                            </Button>
                                        </Grid>
                                    </Grid> */}
                                    <Box
                                        mt={3}
                                        mb={1}
                                    >
                                        <Typography
                                            align="center"
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            Login with email address
                                        </Typography>
                                    </Box>
                                    <InputLabel 
                                        maxWidth
                                        align="center"
                                        error={true} 
                                    >
                                        {errorMessage}
                                    </InputLabel>
                                    <InputLabel 
                                        maxWidth
                                        align="center"
                                        style={ {color: 'green'} }
                                    >
                                        { successMessage }
                                    </InputLabel>
                                    <TextField
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="Email Address"
                                        margin="normal"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="email"
                                        value={values.email}
                                        variant="outlined"
                                    />
                                    <TextField
                                        error={Boolean(touched.password && errors.password)}
                                        fullWidth
                                        helperText={touched.password && errors.password}
                                        label="Password"
                                        margin="normal"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password"
                                        value={values.password}
                                        variant="outlined"
                                    />
                                    <Box my={2}>
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Sign in now
                                        </Button>
                                    </Box>
                                    <Typography
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        Forgot your password?
                                        {' '}
                                        <Button onClick={ (e) => resetPassword(values.email) }>
                                            Reset your password
                                        </Button>
                                    </Typography>
                                </form>
                            )}
                    </Formik>
                </Container>
            </Box>
            <Backdrop className={ classes.backdrop } open={ waitOpen }>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

export default LoginView;
