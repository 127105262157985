import React, { useState, setValues } from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Badge,
    Box,
    Card,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    Typography,
    makeStyles,
    colors,
    Divider,
    InputLabel,
    Input,
    Button,
} from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { PropTypes } from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     height: '100%',
    // },
    // content: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     height: '120px',
    // },
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    }
}));

const ScriptExecution = ({ className, action, title, description, onClick, children, ...rest }) => {
    const classes = useStyles();

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                >
                    {title}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    {description}
                </Typography>
            </CardContent>
            <Box flexGrow={1} />
            <Divider />
            <Box p={2}>
                <Grid
                    container
                    justify="space-between"
                    spacing={2}
                >
                    <Grid
                        className={classes.statsItem}
                        align="center"
                        item
                    >
                        <Button 
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={(e) => onClick(e, action)}
                        >
                            Execute
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default ScriptExecution;