import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results'
import { UserContext } from 'src/providers/UserProvider'
import CONFIG from 'src/config/config'
const queryString = require('query-string');


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ExecutionList = () => {
    const classes = useStyles();
    const {search} = useLocation();
    const params = queryString.parse(search.slice(1))
    const [executions, setExecutions] = useState([]);
    const marvinUser = React.useContext(UserContext);


    const getLogs = (logedUser) => {
        console.log('updating executions');
        let result = [];
        try {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", CONFIG.API_URL + '/scripts/execution', false); // False = Sync
            xmlHttp.setRequestHeader('Content-Type', 'application/json');
            xmlHttp.setRequestHeader('Authorization', logedUser.token);
            xmlHttp.setRequestHeader('Access-Control-Allow-Origin', '*')
            xmlHttp.send();

            if (xmlHttp.status == 200) {
                result = JSON.parse(xmlHttp.response);
                console.log('executions updated');
            }
            else {
                console.error('Error updating executions => ' + xmlHttp.status);
            }
        }
        catch(error) {
            console.error("[ERROR] Updating data from server");
            console.error(error);
        }
        return result;
    }

    React.useEffect(() => {
        let data = getLogs(marvinUser);
        setExecutions(data);
    }, [])

    


    
    return (
        <Page
            className={classes.root}
            title="Execution"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                    >
                        Executions
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Results executions={executions} />
                </Box>
            </Container>
        </Page>
    );
}

export default ExecutionList;
