import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    Box,
    Card,
    Link,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    makeStyles,
    colors,
    Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import StatusIcon from './StatusIcon'


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[900]
    },
    greenIcon: {
        color: colors.green[900]
    }
}));

const Results = ({ className, executions, ...rest }) => {
    const classes = useStyles();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Script</TableCell>
                                <TableCell>Robot</TableCell>
                                <TableCell>Exec Time</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Date/time</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {executions.slice(page * limit, page * limit + limit).map((execution) => (
                                <TableRow to="/teste">
                                    <TableCell>
                                        <StatusIcon status={execution.execStatus} />
                                    </TableCell>
                                    <TableCell>{execution.scriptName}</TableCell>
                                    <TableCell>{execution.robotName}</TableCell>
                                    <TableCell>
                                        {
                                            isNaN(execution.executionTime)
                                            ? "-"
                                            : parseFloat(execution.executionTime).toFixed(1)
                                        }
                                    </TableCell>
                                    <TableCell>{execution.execStatus}</TableCell>
                                    <TableCell>{moment.unix(execution.logTime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                    <TableCell>
                                        <Button 
                                            to={execution.id}
                                            component={RouterLink}
                                        >
                                            Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={executions.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>
    );
}

Results.prototype = {
    className: PropTypes.string,
    executions: PropTypes.array.isRequired,
}
export default Results;