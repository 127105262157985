import React from 'react';
import {
    Button,
    Box,
    Drawer,
    Grid,
    List,
    ListItem,
    ListSubheader,
    TextField,
    makeStyles,
    withStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { DataSet, Network } from 'vis-network/standalone';
import NavBar from './NavBar';
import ProcessCanvas from 'src/components/ProcessCanvas';

const drawerWidth = 300;
const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: 65,
        height: 'calc(100% - 65px)',
    },
  });


class Properties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    
    inputChangeHandler(event, idx) {
        let field = event.target.id;
        this.props.selectedNode.params[idx].value = event.target.value;
        this.setState({});
    }

    nameChangeHandler(event) {
        let field = event.target.id;
        if (field == 'name'){
            this.props.selectedNode.name = event.target.value;
        }
        this.setState({});
    }

    labelChangeHandler(event) {
        let field = event.target.id;
        if (field == 'label'){
            this.props.selectedNode.label = event.target.value;
        }
        this.setState({});
    }


    render() {
        let values = {}
        // values['name'] = this.props.selectedNode.name != null ? this.props.selectedNode.name : '';
        // this.state = values;
        const { classes } = this.props;

        return (
            <Drawer 
                variant="persistent"
                anchor='right'
                open={ this.props.opened }
                className={ classes.drawer }
                classes={{ paper: classes.drawerPaper }}
            >
                <List>
                    <ListSubheader component="div" id="action-menu">
                        Action Properties
                    </ListSubheader>
                    <ListItem>
                        <TextField 
                            required={ false }
                            id="label"
                            label="Description"
                            helperText="Action description"
                            value={ this.props.selectedNode.label || '' } 
                            onChange={ (e) => this.labelChangeHandler(e) }
                            fullWidth
                        />
                    </ListItem>
                    <ListItem>
                        <TextField 
                            required={ false }
                            id="name"
                            label="Name"
                            helperText="Name for action returns"
                            value={ this.props.selectedNode.name || '' } 
                            onChange={ (e) => this.nameChangeHandler(e) }
                            fullWidth
                        />
                    </ListItem>
                    {
                        this.props.selectedNode.params != null ? this.props.selectedNode.params.map((item, idx) => (
                            <ListItem>
                                <TextField
                                    required={ item.obligatory }
                                    id={ item.param }
                                    label={ item.name }
                                    value={ item.value || item.default || '' }
                                    helperText={ item.help }
                                    onChange={ (e) => this.inputChangeHandler(e, idx) }
                                    fullWidth
                                />
                            </ListItem>
                        ))
                        : ''
                    }
                    <ListItem>
                        <Grid container xs={12} spacing={1}>
                            <Grid item xs={8}>
                                <Button variant="contained" onClick={ () => this.props.closeCallback() } fullWidth>
                                    Close
                                </Button>
                            </Grid>
                            <Grid item xs={4} alignItems="flex-end">
                                <Button variant="contained" color="secondary" onClick={ this.props.deleteCallback }>Delete</Button>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Drawer>
        );
    }
}


export default withStyles(styles)(Properties);
// export default Properties;
