import React from 'react';
import clsx from 'clsx';
import {
    Card,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    colors,
    makeStyles,
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PropTypes } from 'prop-types';
import LogItem from './LogItem'


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[900]
    },
    orangeIcon: {
        color: colors.orange[600],
    },
    blueIcon: {
        color: colors.indigo[600],
    },
    greenIcon: {
        color: colors.green[900]
    }
}));

const MailList = ({ className, execution, ...rest }) => {
    const classes = useStyles();

    let mailList;

    if (execution.to && execution.to.length > 0) {
        mailList = execution.to.slice().map((mail) => (
            <ListItem button>
                <ListItemText>{mail}</ListItemText>
            </ListItem>
        ))
    }
    else {
        mailList = <ListItem button><ListItemText primary="No e-mail sent" /></ListItem>
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <List
                component="nav"
                subheader={
                    <ListSubheader>Log Mail Receivers</ListSubheader>
                }
            >
                { mailList }
            </List>
        </Card>

    );
}

MailList.prototype = {
    className: PropTypes.string,
    execution: PropTypes.array.isRequired,
}

export default MailList;