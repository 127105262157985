import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    Card,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    makeStyles,
    colors,
    Button
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { PropTypes } from 'prop-types';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[900]
    },
    orangeIcon: {
        color: colors.orange[600],
    },
    blueIcon: {
        color: colors.indigo[600],
    },
    greenIcon: {
        color: colors.green[900]
    }
}));

const Results = ({ className, status, ...rest }) => {
    const classes = useStyles();
    
    let icon = <ErrorRoundedIcon className={classes.redIcon} />
    if (status == "ACTIVE") {
        icon = <CheckCircleRoundedIcon className={classes.greenIcon} />
    }
    else if (status == "SUSPENDED") {
        icon = <TrackChangesIcon className={classes.orangeIcon} />
    }
    return icon;
}

Results.prototype = {
    className: PropTypes.string,
    status: PropTypes.string.isRequired,
}
export default Results;