import { CallToActionOutlined } from "@material-ui/icons";

const createCode = (data) => {
    let startNode = data.nodes.get(0);
    let finishNode = data.nodes.get(1);

    let code = '';
    let indent = '';

    let currentAction = startNode;

    while (currentAction != null) {
        // Get the edges to check how many actions are linked with actual
        let link = data.edges.get({
            filter: function(item) { return item.from == currentAction.id }
        });

        if (link.length == 1){
            currentAction = data.nodes.get(link[0].to);
            if (processAction(currentAction)) {
                code += indent + createCommandLine(currentAction) + '\n';
            }
        }

        else if (link.length > 1) {
            alert('Action ' + currentAction.label + ' has more than one exit link');
            currentAction = null;
        }
        
        else {
            if (currentAction.command != "finish") {
                alert('Uncompleted process! Last action is not finish');
            }
            currentAction = null;
        }

    }

    return code;
}


const addIndent = (action) => {
    switch(action.command) {
        case 'if-start': return true;
        case 'loop-start': return true;
        default: return false;
    }
};

const removeIndent = (action) => {
    switch(action.command) {
        case 'if-end': return true;
        case 'loop-end': return true;
        default: return false;
    }
};

const processAction = (action) => {
    switch(action.command) {
        case 'start': return false;
        case 'finish': return false;
        default: return true;
    }
};


const createCommandLine = (action) => {
    let commandLine = action.command + "(";
    let hasParam = false;

    action.params.forEach(param => {

        if (
            param.obligatory || 
            (param.value != null && param.value != '' && param.value != param.default)
        ) {
            hasParam = true;
            commandLine += param.param + '=';
            if (isText(param)){
                commandLine += "\"";
            }

            // Define value
            if (param.value == null && param.default != null) // Has default value and value is null
                commandLine += param.default; // Set default

            else if (param.value == null) // Doesn't have default and value is null
                commandLine += 'None' // Set None
            
            else // There is a value setted
                commandLine += param.value; // Set value

            if (isText(param)){
                commandLine += "\"";
            }
            commandLine += ", ";
        }

    });
    if (hasParam)
        commandLine = commandLine.substr(0, commandLine.length - 2);

    if (action.name != null && action.name != ''){
        commandLine += ", name=\"" + action.name + "\"";
    }
    commandLine += ")";

    if (action.label != null && action.label != '' && action.label != action.command)
        commandLine += " # " + action.label

    return commandLine;
}


const isText = (param) => {
    return param.type == "text" && param.value != null && !param.value.startsWith("$")
}

export default createCode;