import React from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import StudioView from 'src/views/app/studio';
import NotFoundView from 'src/views/app/errors/NotFoundView';
import ScriptExecutions from 'src/views/scripts/execution'
import ScriptExecutionDetail from 'src/views/scripts/execution/detail'
import Robots from 'src/views/robots'
import RobotDetail from 'src/views/robots/detail'



const routes = [
    // {
    //     path: 'scripts',
    //     element: <DashboardLayout />,
    //     children: [
    //         { path: 'execution', element: <ScriptExecutions /> },
    //         { path: 'execution/:executionId', element: <ScriptExecutionDetail /> },
    //     ]
    // },
    // {
    //     path: 'robots',
    //     element: <DashboardLayout />,
    //     children: [
    //         { path: '/', element: <Robots /> },
    //         { path: '/:robotId', element: <RobotDetail /> },
    //     ]
    // },
    // {
    //     path: 'app',
    //     element: <DashboardLayout />,
    //     children: [
    //         { path: 'dashboard', element: <DashboardView /> },
    //         { path: '*', element: <NotFoundView /> }
    //     ]
    // },
    {
        path: '*',
        element: <DashboardLayout />,
        children: [
            { path: '404', element: <NotFoundView /> },
            { path: '*', element: <StudioView /> },
        ]
    }
];

export default routes;
