import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    ListItem,
    ListItemText,
    Typography,
    makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    listIcon: {
        minWidth: '35px',
    }
}));

const NavItemAction = ({
    action,
    network,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <Box>
            <ListItem disableGutters button className={ classes.nested } onClick={() => network.addNodeMode()}>
                <ListItemText>
                    <Typography variant="body2">
                        { action.title + " - " + action.action }
                    </Typography>
                </ListItemText>
            </ListItem>
        </Box>
    );
};

NavItemAction.propTypes = {
    action: PropTypes.object
};

export default NavItemAction;
