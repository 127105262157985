import React from 'react';
import clsx from 'clsx';
import {
    Card,
    List,
    ListItemText,
    ListItem,
    ListSubheader,
    colors,
    makeStyles,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PropTypes } from 'prop-types';
import LogItem from './LogItem'


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[900]
    },
    orangeIcon: {
        color: colors.orange[600],
    },
    blueIcon: {
        color: colors.indigo[600],
    },
    greenIcon: {
        color: colors.green[900]
    }
}));

const LogList = ({ className, log, ...rest }) => {
    const classes = useStyles();

    let logItem;

    if (log && log.length > 0) {
        logItem = log.slice().map((log_item) => (
            <LogItem type={log_item.type} message={log_item.log} />
        ))
    }
    else{
        logItem = <ListItem button><ListItemText primary="No log messages to show" /></ListItem>
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader>Log Messages</ListSubheader>
                    }
                >
                    { logItem }
                </List>
            </PerfectScrollbar>
        </Card>

    );
}

LogList.prototype = {
    className: PropTypes.string,
    log: PropTypes.array.isRequired,
}

export default LogList;