import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Collapse,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    makeStyles
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
} from '@material-ui/icons';
import NavItemAction from './NavItemAction';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    listIcon: {
        minWidth: '35px',
    }
}));

const NavItem = ({
    module,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box>
            <ListItem disableGutters button onClick={ handleClick }>
                <ListItemIcon className={ classes.listIcon }>
                    <Icon>
                        <img src={ "/static/actions/" + module.icon } width="24" height="24" />
                    </Icon>
                </ListItemIcon>
                <ListItemText primary={ module.title } />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={ open } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { module.actions.map((item) => (<NavItemAction action={ item } />)) }
                </List>
            </Collapse>
        </Box>
    );
};

NavItem.propTypes = {
    module: PropTypes.object
};

export default NavItem;
