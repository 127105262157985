import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results'
import { UserContext } from 'src/providers/UserProvider'
import CONFIG from 'src/config/config'
import ExecutionList from './ExecutionList';
import ScriptExecutionDetail from './detail';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ScriptExecutions = () => {
    const marvinUser = React.useContext(UserContext);
    return (<ExecutionList />);
    // if (params.id) {
    //     return (<ScriptExecutionDetail executionId={params.id}/>);
    // }
    // else {
    //     return (<ExecutionList />);
    // }
}

export default ScriptExecutions;
