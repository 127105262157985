import React from 'react';
import {
    Box,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    center: {
        position: "absolute",
        left: '50%',
        top: '50%',
    }
}));

const LoadingMarvinConsole = () => {
    const classes = useStyles();
    return (
        <Box
            className={classes.center}
            alignItems='center'
            justifyContent='center'
        >
            <CircularProgress/>
        </Box>
    );
}

export default LoadingMarvinConsole;