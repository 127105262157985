import React from 'react';
import {
    AppBar,
    Button,
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    Tab,
    Tabs,
    TextField,
    makeStyles,
} from '@material-ui/core';
// Ace Editor
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import DeleteIcon from '@material-ui/icons/Delete';
import ArrowIcon from '@material-ui/icons/CallMade';
import SaveIcon from '@material-ui/icons/GetApp';
import DrawModeIcon from '@material-ui/icons/AccountTree';
import DevModeIcon from '@material-ui/icons/DeveloperMode';
import Page from 'src/components/Page';
import { DataSet, Network } from 'vis-network/standalone';
import NavBar from './NavBar';
import SaveModal from './saveModal';
import ProcessCanvas from 'src/components/ProcessCanvas';
import Properties from './properties';
import createCode from './createCode';
import JSZip from 'jszip';
import saveAs from 'file-saver';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    appBar: {
        position: 'aboslute',
        top: 64,
        paddingLeft: 8,
        [theme.breakpoints.up('lg')]: {
            left: 256
        },
        width: '100%',
        background: 'white'
    },
    wrapper: {
        // display: 'flex',
        position: 'absolute',
        // flex: '1 1 auto',
        // overflow: 'hidden',
        paddingTop: 100,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        width: '100%',
        height: '100%',
    },
    editor: { 
        paddingTop: 25, 
        width: '100%', 
        height: '100%' 
    },
    procView: {
        paddingTop: 25,
        width: '100%',
        height: '100%',
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 10
    },
}));

const Studio = ({
    onMobileClose,
    openMobile
}) => {
    const classes = useStyles();
    const domNode = React.useRef(null);
    const network = React.useRef(null);
    const selectedAction = React.useRef({});
    const selectedNode = React.useRef({});
    
    const [propertiesVisible, setPropertiesVisible] = React.useState(false);
    const [mode, setMode] = React.useState('draw');
    const [code, setCode] = React.useState('');
    const [script, setScript] = React.useState('');
    const [saveOpen, setSaveOpen] = React.useState(false);
    const [selected, setSelected] = React.useState('');

    // const loadFile = require('src/config/testProcess.json');
    const loadFile = require('src/config/basicProcess.json');

	var data = {
		nodes: new DataSet(loadFile.nodes),
		edges: new DataSet(loadFile.edges),
	};

	var options = { 
		physics: {enabled: false },
		interaction: {
			multiselect: false, // to simplify node manipulation
		},
		manipulation: {
			enabled: false,
			addEdge: function(edgeData, callback){
				edgeData['arrows'] = 'to';
				edgeData['smooth'] = { type: 'cubicBezier' };
                setSelected('');
                callback(edgeData)
			},
			
			addNode: function(nodeData, callback) {
				nodeData['shape'] = 'box';
                nodeData['label'] = selectedAction.current.action;
                nodeData['title'] = selectedAction.current.action;
                nodeData['command'] = selectedAction.current.action;
                nodeData['params'] = selectedAction.current.params;
				nodeData['margin'] = 15;
				nodeData['shadow'] = {enabled: true};
				nodeData['color'] = { 
					border: '#000000',
					background: '#FFFFFF',
					highlight: {
						border: '#FF0000',
						background: '#F0F0F0',
					}
                };
                selectedNode.current = nodeData;
                openProperties();
                setSelected('');
				callback(nodeData);
			}
		}
    };
    


	React.useEffect(() => {
        if (network.current == null){
            network.current = new Network(domNode.current, data, options);

            network.current.on('click', (properties) => {
                var ids = properties.nodes;
                if (ids.length > 0) {
                    var clickedNodes = data.nodes.get(ids);
                    if (selectedNode.current.id != clickedNodes[0].id) {
                        closeProperties();
                    }

                    console.log(clickedNodes[0]);
                    selectedNode.current = clickedNodes[0];
                    openProperties();
                }
                else {
                    // selectedNode.current = {};
                    closeProperties();
                }
            });
        }
    }, [domNode, network, data, options]);



    const actionClick = (payload) => {
        selectedAction.current = JSON.parse(JSON.stringify(payload));
        if (network.current != null) {
            network.current.addNodeMode();
        }
        setSelected('');
    }


    
    const linkActionsClick = () => {
        if (selected == 'link') {
            setSelected('');
            network.current.disableEditMode();
        }
        else {
            if (network.current != null) {
                setSelected('link');
                network.current.addEdgeMode();
            }
        }
    }


    const openProperties = () => {
        setPropertiesVisible(true);
    }


    const closeProperties = () => {
        if (selectedNode.current.id != null) {
            let update = selectedNode.current;
            delete update.x;
            delete update.y;
            network.current.body.data.nodes.update(update);
        }
        setPropertiesVisible(false);
        selectedNode.current = {};
    }


    const deleteSelected = () => {
        if (network.current != null) {
            closeProperties();
            network.current.deleteSelected();
        }
    }


    const drawMode = () => {
        setMode('draw');
    }


    const save = () => {
        setSaveOpen(true);
    }


    const onSave = (script, error) => {
        if (!error) {
            setScript(script);
            // Create updated version of code
            let generatedCode = createCode(network.current.body.data);
            setCode(generatedCode);
            // Create flow JSON
            let expNodes = network.current.body.data.nodes.get()
            let positions = network.current.getPositions();

            for (var nodeIdx in expNodes) {
                let nodeId = expNodes[nodeIdx].id;
                if (positions[nodeId]) {
                    expNodes[nodeIdx].x = positions[nodeId].x
                    expNodes[nodeIdx].y = positions[nodeId].y
                }
            }

            let flow = {
                'nodes': expNodes,
                'edges': network.current.body.data.edges.get()
            }
            
            // Add files to ZIP
            let zip = new JSZip();
            zip.file(script + '/script.mvn', generatedCode);
            zip.file(script + '/fail.mvn', '');
            zip.file(script + '/process.flw', JSON.stringify(flow));

            // Create a ZIP file
            zip.generateAsync({type: "blob"}).then((blob) => {
                saveAs(blob, script + '.zip');
            });
        }

        setSaveOpen(false);
    }


    const devMode = () => {
        let generatedCode = createCode(network.current.body.data);
        setCode(generatedCode);
        setMode('dev');
    }


    const generateCode = () => {
        let generatedCode = createCode(network.current.body.data);
        alert(generatedCode);
    }



    return (
        <>
            <Box>
                <AppBar color="default" className={ classes.appBar }>
                    <div>
                        <IconButton onClick={(e) => save(script, null) } title="Save" color="primary" classes={{ label: classes.iconButtonLabel }}>
                            <SaveIcon />
                            <div>Save</div>
                        </IconButton>
                        <IconButton onClick={ (e) => linkActionsClick() } title="Link actions" color={selected == 'link' ? 'secondary' : 'primary'} classes={{ label: classes.iconButtonLabel }}>
                            <ArrowIcon />
                            <div>Link</div>
                        </IconButton>
                        <IconButton onClick={ (e) => deleteSelected() } title="Delete selected item" color="primary" classes={{ label: classes.iconButtonLabel }}>
                            <DeleteIcon />
                            <div>Delete</div>
                        </IconButton>
                        <IconButton onClick={ (e) => drawMode() } title="Process draw mode" color={mode == 'draw' ? 'secondary' : 'primary'} classes={{ label: classes.iconButtonLabel }}>
                            <DrawModeIcon />
                            <div>Draw Mode</div>
                        </IconButton>
                        <IconButton onClick={ (e) => devMode() } title="Dev Mode" color={mode == 'dev' ? 'secondary' : 'primary'} classes={{ label: classes.iconButtonLabel }}>
                            <DevModeIcon />
                            <div>Dev Mode</div>
                        </IconButton>
                    </div>
                </AppBar>
            </Box>
            <Box>
                <NavBar
                    onMobileClose={ onMobileClose }
                    openMobile={ openMobile }
                    onClick={ actionClick }
                />
                <div className={ classes.wrapper } >
                    <div 
                        id="procView" 
                        ref={ domNode } 
                        className={ classes.procView } 
                        style={{ display: mode == 'draw' ? '' : 'none'}}
                    ></div>
                    <div className={ classes.editor } style={{ display: mode == 'dev' ? '' : 'none'}}>
                        <AceEditor 
                            mode="python"
                            theme="github"
                            name="codeEditor"
                            editorProps={{ $blockScrolling: true }}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true
                            }}
                            width="100%"
                            height="100%"
                            value={ code }
                        />
                    </div>
                </div>
                <Properties 
                    selectedNode={ selectedNode.current }
                    opened={ propertiesVisible }
                    closeCallback={ closeProperties }
                    deleteCallback={ deleteSelected }
                />
            </Box>
            <SaveModal open={ saveOpen } onSave={ onSave } scriptName="" />
        </>
    );
};

export default Studio;
