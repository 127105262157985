import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    colors,
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import { PropTypes } from 'prop-types';
import moment from 'moment';

const iconSize = 48;
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatarIcon: {
        backgroundColor: colors.red[600],
        height: iconSize,
        width: iconSize
    },
    redIcon: {
        backgroundColor: colors.red[600],
        height: iconSize,
        width: iconSize
    },
    greenIcon: {
        backgroundColor: colors.red[600],
        height: iconSize,
        width: iconSize
    },
    differenceIcon: {
        color: colors.red[900]
    },
}));

const Script = ({ className, scriptName, ...rest }) => {
    const classes = useStyles();

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            Script
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {scriptName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatarIcon}>
                            <MoneyIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Script;