import React, { useState } from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    colors,
    makeStyles,
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { PropTypes } from 'prop-types';


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[900]
    },
    orangeIcon: {
        color: colors.orange[600],
    },
    blueIcon: {
        color: colors.indigo[600],
    },
    greenIcon: {
        color: colors.green[900]
    }
}));

const LogItem = ({ className, type, message, ...rest }) => {
    const classes = useStyles();

    let icon = <ChatIcon className={classes.blueIcon} />
    if (type == "success") {
        icon = <CheckCircleIcon className={classes.greenIcon} />
    }
    else if (type == "warning") {
        icon = <WarningIcon className={classes.orangeIcon} />
    }
    else if (type == "error") {
        icon = <ErrorIcon className={classes.redIcon} />
    }

    return (
        <ListItem button>
            <ListItemIcon>{ icon }</ListItemIcon>
            <ListItemText primary={message} />
        </ListItem>
    );
}

LogItem.prototype = {
    className: PropTypes.string,
    type: PropTypes.string,
    message: PropTypes.string,
}

export default LogItem;