import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    Card,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    TablePagination,
    makeStyles,
    colors,
    Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import RobotStatus from './RobotStatus'


const useStyles = makeStyles((theme) => ({
    root: {},
    redIcon: {
        color: colors.red[900]
    },
    orangeIcon: {
        color: colors.orange[600],
    },
    blueIcon: {
        color: colors.indigo[600],
    },
    greenIcon: {
        color: colors.green[900]
    }
}));



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const Results = ({ className, robots, ...rest }) => {
    const classes = useStyles();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('robot');
    const [order, setOrder] = useState('asc');

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleClick = (event, columnId) => {
        setOrder(order == 'asc' ? 'desc' : 'asc');
        setOrderBy(columnId);
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={ orderBy == 'robot' }
                                        direction={ order }
                                        onClick={e => handleClick(e, 'robot')}
                                    >
                                        Robot Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={ orderBy == 'computerName' }
                                        direction={ order }
                                        onClick={e => handleClick(e, 'computerName')}
                                    >
                                        Computer Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={ orderBy == 'status' }
                                        direction={ order }
                                        onClick={e => handleClick(e, 'status')}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(robots, getComparator(order, orderBy)).slice(page * limit, page * limit + limit).map((robot) => (
                                <TableRow to="/teste">
                                    <TableCell>
                                        <RobotStatus status={robot.robotStatus} />
                                    </TableCell>
                                    <TableCell>{robot.name}</TableCell>
                                    <TableCell>{robot.computerName}</TableCell>
                                    <TableCell>
                                        { robot.robotStatus }
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            href={"robots/" + robot.id}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Actions
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={robots.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>
    );
}

Results.prototype = {
    className: PropTypes.string,
    robots: PropTypes.array.isRequired,
}
export default Results;