import React, { useState, setValues } from 'react';
import clsx from 'clsx';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Snackbar,
    TextField,
    Typography,
    makeStyles,
    colors,
    Button,
} from '@material-ui/core';
import Alert from 'src/components/Alert'
import CONFIG from 'src/config/config';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
}));

const ScriptExecution = ({ className, opens, robot, onClose, ...rest }) => {
    const classes = useStyles();
    const [script, setScript] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);
    const storageUser = JSON.parse(localStorage.getItem('marvinUser'));
    const [marvinUser, setMarvinUser] = React.useState(storageUser);

    const handleChange = (event) => {
        setScript(event.target.value);
    };

    const handleClose = (event) => {
        setErrorMessage("");
        onClose();
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleExecuteClick = () => {
        if (script == "") {
            setErrorMessage("Script name can't be empty");
        }
        else {
            setErrorMessage("");
            sendMessage(marvinUser, robot.id, script);
            setSnackOpen(true);
            handleClose();
        }
    }


    const sendMessage = (logedUser, robotId, script) => {
        console.log('executing script ' + script);
        try {
            let payload = JSON.stringify({
                "robotId": robotId,
                "script": script
            });
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("PUT", CONFIG.API_URL + '/scripts/execution/', false); // False = Sync
            xmlHttp.setRequestHeader('Content-Type', 'application/json');
            xmlHttp.setRequestHeader('Authorization', logedUser.token);
            xmlHttp.setRequestHeader('Access-Control-Allow-Origin', '*');
            xmlHttp.send(payload);

            if (xmlHttp.status == 200) {
                let result = JSON.parse(xmlHttp.response);
                console.log('activities updated');
            }
            else {
                console.error('Error updating activities => ' + xmlHttp.status);
            }
        }
        catch(error) {
            console.error('[ERROR] Error updating data from server');
            console.error(error);
        }
    }


    return (
        <Box className={clsx(classes.root, className)}>
            <Dialog
                open={opens}
                fullWidth={true}
                maxWidth="sm"
                scroll={'paper'}
            >
                <DialogTitle>
                    Script Execution
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                error={Boolean(errorMessage)}
                                helperText={errorMessage ? errorMessage : "Name of script to execute"}
                                label="Script name"
                                name="robot"
                                onChange={(e) => {handleChange(e)}}
                                required
                                value={script}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={handleExecuteClick}
                    >
                        Execute
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar 
                open={snackOpen} 
                autoHideDuration={6000} 
                onClose={handleCloseSnack}
            >
                <Alert severity="success">
                    Execution of {script} sent to {robot.name}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ScriptExecution;