import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    colors,
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import StatusIcon from './StatusIcon'

const iconSize = 48;
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatarIcon: {
        height: iconSize,
        width: iconSize
    },
    redIcon: {
        backgroundColor: colors.red[600],
        height: iconSize,
        width: iconSize
    },
    greenIcon: {
        backgroundColor: colors.green[600],
        height: iconSize,
        width: iconSize
    },
    differenceIcon: {
        color: colors.red[900]
    },
}));

const Status = ({ className, status, ...rest }) => {
    const classes = useStyles();
    let avatarIcon = status ? classes.greenIcon : classes.redIcon;

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            Status
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {status}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StatusIcon status={status} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Status;