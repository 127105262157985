const actionConfig = [
    {
        icon: 'recaptcha.svg',
        title: 'Anticaptcha',
        actions: [
            {
                title: 'Recaptcha',
                action: 'anticaptcha.recaptcha_v2',
                 params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser',
                        help: 'Browser object (given from chrome.open) with the page that has the captcha on it',
                        type: 'object',
                        value: null,
                        default: null

                    },
                    {
                        param: 'anticaptcha_key',
                        obligatory: true,
                        name: 'Anticaptcha Key',
                        help: 'Anti-Captcha Account Key, to identify your account and credits at Anti-Captcha.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'callback_function',
                        obligatory: true,
                        name: 'Callback Function Name',
                        help: 'Set the name of callback function to call after ReCaptcha change is done.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'hCaptcha',
                action: 'anticaptcha.hcaptcha',
                 params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser',
                        help: 'Browser object (given from chrome.open) with the page that has the captcha on it',
                        type: 'object',
                        value: null,
                        default: null

                    },
                    {
                        param: 'anticaptcha_key',
                        obligatory: true,
                        name: 'Anticaptcha Key',
                        help: 'Anti-Captcha Account Key, to identify your account and credits at Anti-Captcha.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'callback_function',
                        obligatory: true,
                        name: 'Callback Function Name',
                        help: 'Set the name of callback function to call after ReCaptcha change is done.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            }
        ]
    },
    {
        icon: 'api.svg',
        title: 'API',
        actions: [
            {
                title: 'GET',
                action: 'api.get',
                 params: [
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'URL where the request will be sent',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'data',
                        obligatory: false,
                        name: 'Data',
                        help: 'Content that will be send as request body',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'How many seconds to wait for the server to send data before giving up',
                        type: 'number',
                        value: null,
                        default: null

                    },
                ]
            },
            {
                title: 'POST',
                action: 'api.post',
                 params: [
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'URL where the request will be sent',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'data',
                        obligatory: false,
                        name: 'Data',
                        help: 'Content that will be send as request body',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'Timeout',
                        obligatory: false,
                        name: 'timeout',
                        help: 'How many seconds to wait for the server to send data before giving up',
                        type: 'number',
                        value: null,
                        default: null

                    },
                ]
            },
            {
                title: 'PUT',
                action: 'api.put',
                 params: [
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'URL where the request will be sent',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'data',
                        obligatory: false,
                        name: 'Data',
                        help: 'Content that will be send as request body',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'How many seconds to wait for the server to send data before giving up',
                        type: 'number',
                        value: null,
                        default: null

                    },
                ]
            },
            {
                title: 'DELETE',
                action: 'api.delete',
                 params: [
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'URL where the request will be sent',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'data',
                        obligatory: false,
                        name: 'Data',
                        help: 'Content that will be send as request body',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'How many seconds to wait for the server to send data before giving up',
                        type: 'number',
                        value: null,
                        default: null

                    },
                ]
            },
            {
                title: 'PATCH',
                action: 'api.patch',
                 params: [
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'URL where the request will be sent',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'data',
                        obligatory: false,
                        name: 'Data',
                        help: 'Content that will be send as request body',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'How many seconds to wait for the server to send data before giving up',
                        type: 'number',
                        value: null,
                        default: null

                    },
                ]
            },
        ]
    },
    {
        icon: 'aws-s3.svg',
        title: 'AWS S3',
        actions: [
            {
                title: 'Download',
                action: 'aws.s3.download',
                 params: [
                    {
                        param: 'bucket',
                        obligatory: true,
                        name: 'Bucket',
                        help: 'The name of the bucket containing the objects',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'object_name',
                        obligatory: true,
                        name: 'Object Name',
                        help: 'Name of the object to be downloaded',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'save_path',
                        obligatory: true,
                        name: 'Save Path',
                        help: 'Path to folder where files should be saved',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'aws_access_key_id',
                        obligatory: false,
                        name: 'AWS Access Key',
                        help: 'AWS access key id for authentication',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'aws_secret_access_key',
                        obligatory: false,
                        name: 'AWS Secret Key',
                        help: 'AWS secret access key for authentication',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'List',
                action: 'aws.s3.list',
                 params: [
                    {
                        param: 'bucket',
                        obligatory: true,
                        name: 'Bucket',
                        help: 'The name of the bucket containing the objects',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'prefix',
                        obligatory: true,
                        name: 'Prefix',
                        help: 'Limits the response to keys that begin with the specified prefix',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'aws_secret_access_key',
                        obligatory: false,
                        name: 'AWS Secret Access Key',
                        help: 'AWS secret access key for authentication',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'aws_secret_access_key',
                        obligatory: false,
                        name: 'AWS Secret Key',
                        help: 'AWS secret access key for authentication',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Upload',
                action: 'aws.s3.upload',
                 params: [
                    {
                        param: 'bucket',
                        obligatory: true,
                        name: 'Bucket',
                        help: 'The name of the bucket containing the objects',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'object_name',
                        obligatory: true,
                        name: 'S3 Object Name',
                        help: 'Name of the object to be downloaded',
                        type: 'text',
                        value: null,
                        default: null

                    },
                    {
                        param: 'aws_access_key_id',
                        obligatory: false,
                        name: 'AWS Access Key',
                        help: 'AWS access key id for authentication',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'aws_secret_access_key',
                        obligatory: false,
                        name: 'AWS Secret Key',
                        help: 'AWS secret access key for authentication',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'file_mime_type',
                        obligatory: false,
                        name: 'File Mime Type',
                        help: 'Define file MIME type',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
        ]
    },
    {
        icon: 'chrome.svg',
        title: 'Chrome',
        actions: [
            {
                title: 'Change Select',
                action: 'chrome.change_select',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'value',
                        obligatory: true,
                        name: 'Value',
                        help: 'Value that should be setted to <select> element.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Click at Element',
                action: 'chrome.click_element',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                    {
                        param: 'clicks',
                        obligatory: false,
                        name: 'Clicks',
                        help: 'Number of clicks to be performed at position (default=1).',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Defines witch mouse button should de used for drag \'left\', \'middle\' or \'right\' (default=\'left\').',
                        type: 'text',
                        value: null,
                        default: 'left'
                    },
                    {
                        param: 'interval',
                        obligatory: false,
                        name: 'Interval',
                        help: 'Interval between each click, for slower computers, consider set a higher value than default (default=0 - instantaneous).',
                        type: 'number',
                        value: null,
                        default: 0
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Timeout to wait for element appear (default=5 seconds).',
                        type: 'number',
                        value: null,
                        default: 5
                    },
                ]
            },
            {
                title: 'Close',
                action: 'chrome.close',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Execute JavaScript',
                action: 'chrome.execute_js',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'expression',
                        obligatory: true,
                        name: 'Expression',
                        help: 'Javascript expression to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'await_promise',
                        obligatory: false,
                        name: 'Await Promise',
                        help: 'Defines if expression will await for any promise, in case of async expression (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    }
                ]
            },
            {
                title: 'Focus element',
                action: 'chrome.focus',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Get Element',
                action: 'chrome.get_element',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'depth',
                        obligatory: false,
                        name: 'Depth',
                        help: 'Define the depth (levels) of children elements to return (default=1).',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Get Element Center',
                action: 'chrome.get_element_center',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Get Element Position',
                action: 'chrome.get_element_position',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Get Elements',
                action: 'chrome.get_elements',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'depth',
                        obligatory: false,
                        name: 'Depth',
                        help: 'Define the depth (levels) of children elements to return (default=1).',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    }
                ]
            },
            {
                title: 'Check if has Download in Progress',
                action: 'chrome.has_download_in_progress',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Is Element Visible',
                action: 'chrome.get_elements',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Timeout to wait for element appear (default=5 seconds).',
                        type: 'number',
                        value: null,
                        default: 5
                    },
                ]
            },
            {
                title: 'Navigate',
                action: 'chrome.navigate',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'New URL to set on browser.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Open',
                action: 'chrome.open',
                params: [
                    {
                        param: 'url',
                        obligatory: true,
                        name: 'URL',
                        help: 'URL to open',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'chrome_path',
                        obligatory: false,
                        name: 'Chrome Path',
                        help: 'Path to Chrome.exe.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Query Selector',
                action: 'chrome.query_selector',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'depth',
                        obligatory: false,
                        name: 'Depth',
                        help: 'Define the depth (levels) of children elements to return (default=1).',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Query Selector All',
                action: 'chrome.query_selector_all',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'depth',
                        obligatory: false,
                        name: 'Depth',
                        help: 'Define the depth (levels) of children elements to return (default=1).',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Scroll if needed',
                action: 'chrome.scroll_if_needed',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Select Tab',
                action: 'chrome.select_tab',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'tab_name',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Name/Title of the tab to be selected. Don\'t need to be the complete tab name, as this action searchs a tab that contains the given name in any part of it\'s title in a case insensitive way.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Browser Object',
                        help: 'How long (in seconds) this action will wait until the tab_name could be found. This is util when a new tab is created dinamically, which sometimes could have a litle delay (default=10 seconds).',
                        type: 'text',
                        value: null,
                        default: 10
                    },
                ]
            },
            {
                title: 'Wait Element',
                action: 'chrome.wait_element',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Timeout to wait for element appear (default=30 seconds).',
                        type: 'number',
                        value: null,
                        default: 30
                    },
                ]
            },
            {
                title: 'Wait Element to Hide',
                action: 'chrome.wait_element_hide',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Timeout to wait for element appear (default=30 seconds).',
                        type: 'number',
                        value: null,
                        default: 30
                    },
                ]
            },
            {
                title: 'Wait Element to get Visible',
                action: 'chrome.wait_element_visible',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Defines the maximum wait time in seconds to wait until element is present in page (default=30 seconds).',
                        type: 'number',
                        value: null,
                        default: 30
                    },
                ]
            },
            {
                title: 'Wait load',
                action: 'chrome.wait_load',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Defines the maximum wait time in seconds to wait until page is loaded (default=60 seconds).',
                        type: 'number',
                        value: null,
                        default: 60
                    }
                ]
            },
            {
                title: 'Get Element Text',
                action: 'element.get_text',
                params: []
            },
            {
                title: 'Get Element Attribute',
                action: 'element.get_attribute',
                params: [
                    {
                        param: 'attribute_name',
                        obligatory: true,
                        name: 'Attribute Name',
                        help: 'Name/key of the attribute to be returned.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
        ]
    },
    {
        icon: 'config1.svg',
        title: 'Config',
        actions: [
            {
                title: 'Log Receivers',
                action: 'config.log_receivers',
                params:[
                    {
                        param: 'emails',
                        obligatory: true,
                        name: 'E-mails',
                        help: 'A string with a list of e-mail addresses separated by , or ; or, a list of strings, where each element must be a single e-mail address',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'csv.svg',
        title: 'CSV',
        actions: [
            {
                title: 'Open',
                action: 'csv.open',
                params:[
                    {
                        param: 'filepath',
                        obligatory: true,
                        name: 'File Path',
                        help: 'Path to CSV file',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'encoding',
                        obligatory: true,
                        name: 'Encoding',
                        help: 'File encoding',
                        type: 'text',
                        value: null,
                        default: 'UTF-8'
                    }
                ]
            },
            {
                title: 'Get Lines',
                action: 'csv.get_lines',
                params:[
                    {
                        param: 'csv_file',
                        obligatory: true,
                        name: 'CSV file instance',
                        help: 'CSV instance given from csv.open',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'delimiter',
                        obligatory: false,
                        name: 'Column Delimiter',
                        help: 'Defince CSV delimiter',
                        type: 'text',
                        value: null,
                        default: ';'
                    },
                    {
                        param: 'first_line_header',
                        obligatory: false,
                        name: 'First Line Header',
                        help: 'Defines if first line is a header',
                        type: 'bool',
                        value: null,
                        default: true
                    }
                ]
            },
            {
                title: 'Close',
                action: 'csv.close',
                params:[
                    {
                        param: 'csv_file',
                        obligatory: true,
                        name: 'CSV file instance',
                        help: 'CSV instance given from csv.open',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            }
        ]
    },
    {
        icon: 'date.svg',
        title: 'Date',
        actions: [
            {
                title: 'Convert date format',
                action: 'date.convert_date_format',
                params: [
                    {
                        param: 'date_str',
                        obligatory: true,
                        name: 'Date',
                        help: 'Date as text in the current format. This is the input for the convertion.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'input_mask',
                        obligatory: false,
                        name: 'Input mask',
                        help: 'Mask of the input text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    },
                    {
                        param: 'output_mask',
                        obligatory: false,
                        name: 'Output mask',
                        help: 'Mask of the output text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    }
                ]
            },
            {
                title: 'Delta Business Days',
                action: 'date.delta_business_days',
                params: [
                    {
                        param: 'date',
                        obligatory: false,
                        name: 'Date',
                        help: 'base date to calculate the business days delta (default=today).',
                        type: 'text',
                        default: null,
                        value: null
                    },
                    {
                        param: 'days',
                        obligatory: false,
                        name: 'Days',
                        help: 'How many business days should be added or subtracted from the base date. Positive numbers will give future business days, and negative numbers past business days (default=0).',
                        type: 'number',
                        default: 0,
                        value: null
                    },
                    {
                        param: 'mask',
                        obligatory: false,
                        name: 'Mask',
                        help: 'Mask of the output text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    }
                ]
            },
            {
                title: 'Delta Days',
                action: 'date.delta_days',
                params: [
                    {
                        param: 'date',
                        obligatory: false,
                        name: 'Date',
                        help: 'Base date to calculate the days delta (default=today).',
                        type: 'text',
                        default: null,
                        value: null
                    },
                    {
                        param: 'years',
                        obligatory: false,
                        name: 'Years',
                        help: 'How many years should be added or subtracted from the base date. Positive numbers will give future dates, and negative numbers past dates (default=0).',
                        type: 'number',
                        default: 0,
                        value: null
                    },
                    {
                        param: 'months',
                        obligatory: false,
                        name: 'Months',
                        help: 'How many months should be added or subtracted from the base date. Positive numbers will give future dates, and negative numbers past dates (default=0).',
                        type: 'number',
                        default: 0,
                        value: null
                    },
                    {
                        param: 'days',
                        obligatory: false,
                        name: 'Days',
                        help: 'How many days should be added or subtracted from the base date. Positive numbers will give future business days, and negative numbers past business days (default=0).',
                        type: 'number',
                        default: 0,
                        value: null
                    },
                    {
                        param: 'mask',
                        obligatory: false,
                        name: 'Mask',
                        help: 'Mask of the output text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    }
                ]
            },
            {
                title: 'First day of month',
                action: 'date.first_day_of_month',
                params: [
                    {
                        param: 'years',
                        obligatory: false,
                        name: 'Year',
                        help: 'Number of the year to return the first day',
                        type: 'number',
                        default: null,
                        value: null
                    },
                    {
                        param: 'months',
                        obligatory: false,
                        name: 'Month',
                        help: 'Number of the month to return the first day.',
                        type: 'number',
                        default: null,
                        value: null
                    },
                    {
                        param: 'mask',
                        obligatory: false,
                        name: 'Mask',
                        help: 'Mask of the output text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    }
                ]
            },
            {
                title: 'Last day of month',
                action: 'date.last_day_of_month',
                params: [
                    {
                        param: 'years',
                        obligatory: false,
                        name: 'Year',
                        help: 'Number of the year to return the last day',
                        type: 'number',
                        default: null,
                        value: null
                    },
                    {
                        param: 'months',
                        obligatory: false,
                        name: 'Month',
                        help: 'Number of the month to return the last day.',
                        type: 'number',
                        default: null,
                        value: null
                    },
                    {
                        param: 'mask',
                        obligatory: false,
                        name: 'Mask',
                        help: 'Mask of the output text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    }
                ]
            },
            {
                title: 'Today',
                action: 'console.today',
                params: [
                    {
                        param: 'mask',
                        obligatory: false,
                        name: 'Mask',
                        help: 'Mask of the output text date (default=%Y-%m-%d).',
                        type: 'text',
                        default: '%Y-%m-%d',
                        value: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'email.svg',
        title: 'E-mail',
        actions: [
            {
                title: 'Close',
                action: 'email.close',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'quit',
                        obligatory: false,
                        name: 'Quit',
                        help: 'When using POP3 client, to persist all read e-mails and deletions, the quit parameter should be setter to True.',
                        type: 'bool',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Create Imap Client',
                action: 'email.create_imap_client',
                params: [
                    {
                        param: 'user',
                        obligatory: true,
                        name: 'User',
                        help: 'User to authenticate with the Mail Server',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'password ',
                        obligatory: true,
                        name: 'Password',
                        help: 'Password to authenticate with Mail Server',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'host',
                        obligatory: false,
                        name: 'Host',
                        help: 'IP or name to connect with Mail Server',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'port',
                        obligatory: false,
                        name: 'Port',
                        help: 'IP or name to connect with Mail Server used for IMAP connection (default=995).',
                        type: 'number',
                        value: null,
                        default: 995
                    }
                ]
            },
            {
                title: 'Create Pop Client',
                action: 'email.create_pop_client',
                params: [
                    {
                        param: 'user',
                        obligatory: true,
                        name: 'User',
                        help: 'User to authenticate with the Mail Server',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'password ',
                        obligatory: true,
                        name: 'Password',
                        help: 'Password to authenticate with Mail Server',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'host',
                        obligatory: false,
                        name: 'Host',
                        help: 'IP or name to connect with Mail Server',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'port',
                        obligatory: false,
                        name: 'Port',
                        help: 'IP or name to connect with Mail Server used for IMAP connection (default=995).',
                        type: 'number',
                        value: null,
                        default: 995
                    }
                ]
            },
            {
                title: 'Delete',
                action: 'email.delete',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Find Attachments',
                action: 'email.find_attachments',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'attach_name',
                        obligatory: false,
                        name: 'Attach Name',
                        help: 'Name or partial name of the attachment to look for (default=don\'t filter by name).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'mime_type',
                        obligatory: false,
                        name: 'Mime Type',
                        help: 'Mime type to filter attachments (default=don\'t filter by mime type)',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'only_attachments',
                        obligatory: false,
                        name: 'Only Attachments',
                        help: 'Setted to True will include files setted as attachment. Setted to False will include all files, attachments and inline (default=True)',
                        type: 'bool',
                        value: null,
                        default: true
                    }
                ]
            },
            {
                title: 'Get Current Email',
                action: 'email.get_current_email',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'List Folders',
                action: 'email.list_folders',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Move to',
                action: 'email.move_to',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'to_folder',
                        obligatory: true,
                        name: 'Folder Location',
                        help: 'Name of the folder where the current message should be moved to.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Next Email',
                action: 'email.next_email',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Previous Email',
                action: 'email.previous_email',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Save Attachment',
                action: 'email.save_attachment',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'file_path',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Path to foler where attachments should be saved.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'attach_name',
                        obligatory: false,
                        name: 'Attach Name',
                        help: 'Name or partial name of the attachment to look for (default=don\'t filter by name).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'mime_type',
                        obligatory: false,
                        name: 'Mime Type',
                        help: 'Mime type to filter attachments (default=don\'t filter by mime type)',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'only_attachments',
                        obligatory: false,
                        name: 'Only Attachments',
                        help: 'Setted to True will include files setted as attachment. Setted to False will include all files, attachments and inline (default=True)',
                        type: 'bool',
                        value: null,
                        default: true
                    }
                ]
            },
            {
                title: 'Search',
                action: 'email.search',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'search_filter',
                        obligatory: true,
                        name: 'Search',
                        help: 'Search filter to apply on current folder, as defined in IMAP search specification. For futher information access https://tools.ietf.org/html/rfc3501#page-49',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Send Email',
                action: 'email.send_email',
                params: [
                    {
                        param: 'sender',
                        obligatory: true,
                        name: 'Sender',
                        help: 'E-mail to insert on field FROM of the message.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'to',
                        obligatory: true,
                        name: 'To',
                        help: 'E-mails to insert on field TO of message.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'subject',
                        obligatory: true,
                        name: 'Subject',
                        help: 'Subject of the message.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'smtp_user',
                        obligatory: true,
                        name: 'SMTP user',
                        help: 'Username to authenticate with SMTP server.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'smtp_password',
                        obligatory: true,
                        name: 'SMTP password',
                        help: 'Password to authenticate with SMTP server.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'smtp_server',
                        obligatory: true,
                        name: 'SMTP server',
                        help: 'IP or hostname of the SMTP server.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'smtp_port',
                        obligatory: false,
                        name: 'SMTP port',
                        help: 'Port to connect on SMTP server (default=25).',
                        type: 'text',
                        value: null,
                        default: 25
                    },
                    {
                        param: 'tls',
                        obligatory: false,
                        name: 'TLS',
                        help: 'Defines if TLS should be used on communication with server (default=False).',
                        type: 'bool',
                        value: null,
                        default: false
                    },
                    {
                        param: 'text',
                        obligatory: false,
                        name: 'Text',
                        help: 'Content of the message as plain text (default=no text message).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'html',
                        obligatory: false,
                        name: 'HTML',
                        help: 'Content of the message as HTML text (default=no html message).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'Attach_path',
                        obligatory: false,
                        name: 'Attach path',
                        help: 'Path to folder to be attached on the message (default=no attachment)',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Store',
                action: 'email.store',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'message',
                        obligatory: true,
                        name: 'Message',
                        help: 'Store message to be sent to server.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'value',
                        obligatory: true,
                        name: 'Value',
                        help: 'Value to be setted to message.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Update Mailbox',
                action: 'email.update_mailbox',
                params: [
                    {
                        param: 'email_client',
                        obligatory: true,
                        name: 'Client Email',
                        help: 'Client connection to Mail Server. Connections are created using email.create_imap_client or email.create_pop_client.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
        ]
    },
    {
        icon: 'excel.svg',
        title: 'Excel',
        actions: [
            {
                title: 'Append',
                action: 'excel.append',
                params: [
                    {
                        param: 'worksheet',
                        obligatory: true,
                        name: 'Worksheet',
                        help: 'Worksheet to be used for appending.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'line_values',
                        obligatory: false,
                        name: 'Line values',
                        help: 'Array with each index been a different column. Example found in documentation.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'grid_values',
                        obligatory: false,
                        name: 'Grid values',
                        help: 'A grid where each line will represent a line and each line element will represent a column. Example in the documentation',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Convert to .xlsx',
                action: 'excel.convert_to_xlsx',
                params: [
                    {
                        param: 'filepath',
                        obligatory: true,
                        name: 'File path',
                        help: 'Path to the file that will be converted.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Create New',
                action: 'excel.create_new',
                params: []
            },
            {
                title: 'Get Cell',
                action: 'excel.get_cell',
                params: [
                    {
                        param: 'worksheet',
                        obligatory: true,
                        name: 'Worksheet',
                        help: 'Worksheet to be used for cell getting.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'range',
                        obligatory: true,
                        name: 'Range',
                        help: 'Range of the cells to get value using Excel pattern, ex: A1:C3',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Get Dimensions',
                action: 'excel.get_dimensions',
                params: [
                    {
                        param: 'worksheet',
                        obligatory: true,
                        name: 'Worksheet',
                        help: 'Worksheet to return the Dimensions.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Last Line',
                action: 'excel.last_line',
                params: [
                    {
                        param: 'worksheet',
                        obligatory: true,
                        name: 'Worksheet',
                        help: 'Worksheet to return the last line.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Open',
                action: 'excel.open',
                params: [
                    {
                        param: 'filepath',
                        obligatory: true,
                        name: 'File path',
                        help: 'Path to Excel file',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Save',
                action: 'excel.save',
                params: [
                    {
                        param: 'workbook',
                        obligatory: true,
                        name: 'Workbook',
                        help: 'Workbook that has been updated.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'filepath',
                        obligatory: true,
                        name: 'File path',
                        help: 'Path to Excel file',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Select Sheet',
                action: 'excel.select_sheet',
                params: [
                    {
                        param: 'workbook',
                        obligatory: true,
                        name: 'Workbook',
                        help: 'Workbook returned by excel.open.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sheet_name',
                        obligatory: false,
                        name: 'Sheet Name',
                        help: 'Name of the sheet to be returned. If no sheet name is given will return the active sheet.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Update Cell',
                action: 'excel.update_cell',
                params: [
                    {
                        param: 'worksheet',
                        obligatory: true,
                        name: 'Workbook',
                        help: 'Worksheet to be used for cell getting.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'cell',
                        obligatory: true,
                        name: 'Cell',
                        help: 'Reference of the cell, using Excel pattern, ex: A1',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
        ]
    },
    {
        icon: 'file.svg',
        title: 'File',
        actions: [
            {
                title: 'Copy',
                action: 'file.copy',
                params: [
                    {
                        param: 'source',
                        obligatory: true,
                        name: 'Source',
                        help: 'Path to the source folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'destination',
                        obligatory: true,
                        name: 'Destination',
                        help: 'Path to the destionation of the coping folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Delete',
                action: 'file.delete',
                params: [
                    {
                        param: 'path',
                        obligatory: true,
                        name: 'Path',
                        help: 'Path to the folder or file to delete.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'File exists?',
                action: 'file.file_exists',
                params: [
                    {
                        param: 'path',
                        obligatory: true,
                        name: 'Path',
                        help: 'Path to the file to check.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Folder exists?',
                action: 'file.folder_exists',
                params: [
                    {
                        param: 'path',
                        obligatory: true,
                        name: 'Path',
                        help: 'Path to the folder to check.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'List Files',
                action: 'file.list_files',
                params: [
                    {
                        param: 'path',
                        obligatory: true,
                        name: 'Path',
                        help: 'Path or path pattern to return a list of files.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Move',
                action: 'file.move',
                params: [
                    {
                        param: 'source',
                        obligatory: true,
                        name: 'Source',
                        help: 'Path to the source folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'destination',
                        obligatory: true,
                        name: 'Destination',
                        help: 'Path to the destionation of the moving folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Newest file',
                action: 'file.newest_file',
                params: [
                    {
                        param: 'path',
                        obligatory: true,
                        name: 'Path',
                        help: 'Path to check the newest file in folder.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Rename',
                action: 'file.rename',
                params: [
                    {
                        param: 'source',
                        obligatory: true,
                        name: 'Source',
                        help: 'Path to the current folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'destination',
                        obligatory: true,
                        name: 'Destination',
                        help: 'Path to the new folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                // method size does really need 'destination' parameter?
                title: 'Size',
                action: 'file.size',
                params: [
                    {
                        param: 'source',
                        obligatory: true,
                        name: 'Source',
                        help: 'Path to the current folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'destination',
                        obligatory: true,
                        name: 'Destination',
                        help: 'Path to the new folder or file.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
        ]
    },
    {
        icon: 'flow-control.svg',
        title: 'Flow Control',
        actions: [
            {
                title: 'Conditional - Start',
                action: 'if-start',
                params: []
            },
            {
                title: 'Conditional - End',
                action: 'if-end',
                params: []
            },
            {
                title: 'Finish',
                action: 'finish',
                params: []
            },
            {
                title: 'Loop - Start',
                action: 'loop-start',
                params: []
            },
            {
                title: 'Loop - End',
                action: 'loop-end',
                params: []
            },
            {
                title: 'Start',
                action: 'start',
                params: []
            }
        ]
    },
    {
        icon: 'google-sheets.svg',
        title: 'Google Sheets',
        actions: [
            {
                title: 'Append',
                action: 'google.sheets.append',
                params: [
                    {
                        param: 'spreadsheet',
                        obligatory: true,
                        name: 'Spreadsheet',
                        help: 'Spreadsheet variable returned by google.sheets.open.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'cell_range',
                        obligatory: true,
                        name: 'Cell range ',
                        help: 'Range of cells where Marvin will start to append new data in Google Sheets. It could be single cell as A1, defining that all columns and rows shold be inserted after A1 cell, or could be a range A1:C1 new data will be inserted after this range.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'line_values',
                        obligatory: false,
                        name: 'Line values',
                        help: 'Array with each index been a different column. Example found in documentation.',
                        type: 'list:type',
                        value: null,
                        default: null
                    },
                    {
                        param: 'grid_values',
                        obligatory: false,
                        name: 'Grid values',
                        help: 'A grid where each line will represent a line and each line element will represent a column. Example found in documentation.',
                        type: 'list:type',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Open',
                action: 'google.sheets.open',
                params: [
                    {
                        param: 'spreadsheet_id',
                        obligatory: true,
                        name: 'Spreadsheet ID',
                        help: 'ID of the spreadsheet to connect with. This ID could found on Spreadsheet URL. Example found in documentation.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'current_sheet',
                        obligatory: true,
                        name: 'Current sheet',
                        help: 'Name of the sheet to be opened as default, and can be found on the bottom of the Google Sheets screen.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'service_account_path',
                        obligatory: true,
                        name: 'Service Account Path',
                        help: 'path to JSON file with the service account authorization. For more information on how to create this credential, check this link: http://docs.marvin.studio/user-guide/actions/google.sheets/#enable-google-sheets-api-and-create-service-account',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
        ]
    },
    {
        icon: 'helper.svg',
        title: 'Helper',
        actions: [
            {
                title: 'Set Process Var',
                action: 'helper.set_process_var',
                params: [
                    {
                        param: 'name',
                        obligatory: true,
                        name: 'Name',
                        help: 'name of the var to create or update.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
        ]
    },
    {
        icon: 'keyboard.svg',
        title: 'Keyboard',
        actions: [
            {
                title: 'Hot Key Combination',
                action: 'keyboard.hot_key',
                params: [
                    {
                        param: 'hotkey_list',
                        obligatory: true,
                        name: 'Hotkey list',
                        help: 'List of keys in sequence to be triggered as hot key.',
                        type: 'list:type',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Press and Hold',
                action: 'keyboard.key_down',
                params: [
                    {
                        param: 'key',
                        obligatory: true,
                        name: 'Key',
                        help: 'Key to press and hold',
                        type: 'text',
                        value: null,
                        default: null,
                    },
                ]
            },
            {
                title: 'Release key',
                action: 'keyboard.key_up',
                params: [
                    {
                        param: 'key',
                        obligatory: true,
                        name: 'Key',
                        help: 'Key to release',
                        type: 'text',
                        value: null,
                        default: null,
                    },
                ]
            },
            {
                title: 'Press Key',
                action: 'keyboard.press',
                params: [
                    {
                        param: 'key',
                        obligatory: true,
                        name: 'Key',
                        help: 'Key to press',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Type Text',
                action: 'keyboard.type_text',
                params: [
                    {
                        param: 'text',
                        obligatory: true,
                        name: 'Text',
                        help: 'Text to type',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'log.svg',
        title: 'Log',
        actions: [
            {
                title: 'Error Message',
                action: 'console.error',
                params: [
                    {
                        param: 'message',
                        obligatory: true,
                        name: 'Message',
                        help: 'Error message to be shown.',
                        type: 'text',
                        value: null
                    }
                ]
            },
            {
                title: 'Log Message',
                action: 'console.log',
                params: [
                    {
                        param: 'message',
                        obligatory: true,
                        name: 'Message',
                        help: 'Message to be shown.',
                        type: 'text',
                        value: null,
                        default: null

                    }
                ]
            },
            {
                title: 'Success Message',
                action: 'console.success',
                params: [
                    {
                        param: 'message',
                        obligatory: true,
                        name: 'Message',
                        help: 'Success message to be shown.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Warning Message',
                action: 'console.warning',
                params: [
                    {
                        param: 'message',
                        obligatory: true,
                        name: 'Message',
                        help: 'Warning message to be shown.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'mouse.svg',
        title: 'Mouse',
        actions: [
            {
                title: 'Click',
                action: 'mouse.click',
                params: [
                    {
                        param: 'x',
                        obligatory: false,
                        name: 'X position',
                        help: 'Position from the left side of screen',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y',
                        obligatory: false,
                        name: 'Y position',
                        help: 'Position from the top of screen',
                        type: 'number',
                        value: null,
                        default: null,
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Button to use on click',
                        type: 'text',
                        value: null,
                        default: 'left',
                    },
                    {
                        param: 'clicks',
                        obligatory: false,
                        name: 'Clicks',
                        help: 'Number of clicks',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'move_click_delay',
                        obligatory: false,
                        name: 'Move/Click Delay',
                        help: 'Delay between mouse move and click',
                        type: 'number',
                        value: null,
                        default: 0
                    }
                ]
            },
            {
                title: 'Click at Element',
                action: 'mouse.click_element',
                params: [
                    {
                        param: 'browser',
                        obligatory: true,
                        name: 'Browser Object',
                        help: 'Browser instance given from chrome.open.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'selector',
                        obligatory: false,
                        name: 'Selector',
                        help: 'Identifier of the element in selector mode (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'element',
                        obligatory: false,
                        name: 'Element',
                        help: 'Element object to change (default=None).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'inside',
                        obligatory: false,
                        name: 'Inside',
                        help: 'This value define the element object where the selector should be found in (default=all document).',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'iframe_search',
                        obligatory: false,
                        name: 'Iframe Search',
                        help: 'Define if selector should be found in the base document or any documents, even inside iframes. If setted True will search inside all iframes, and if setted False will search only inside the main document (default=True).',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                    {
                        param: 'clicks',
                        obligatory: false,
                        name: 'Clicks',
                        help: 'Number of clicks to be performed at position (default=1).',
                        type: 'number',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Defines witch mouse button should de used for drag \'left\', \'middle\' or \'right\' (default=\'left\').',
                        type: 'text',
                        value: null,
                        default: 'left'
                    },
                    {
                        param: 'interval',
                        obligatory: false,
                        name: 'Interval',
                        help: 'Interval between each click, for slower computers, consider set a higher value than default (default=0 - instantaneous).',
                        type: 'number',
                        value: null,
                        default: 0
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Timeout to wait for element appear (default=5 seconds).',
                        type: 'number',
                        value: null,
                        default: 5
                    }
                ]
            },
            {
                title: 'Click in image',
                action: 'mouse.click_image',
                params: [
                    {
                        param: 'template_image',
                        obligatory: true,
                        name: 'Template Image',
                        help: 'The template image to find on screen and click on it.',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                    {
                        param: 'threshold',
                        obligatory: false,
                        name: 'Threshold',
                        help: 'Definies the minimum percentage of match with template image (default=0.9 - 90%).',
                        type: 'float',
                        value: null,
                        default: 0.9
                    },
                    {
                        param: 'screenshot_img',
                        obligatory: false,
                        name: 'Screenshot image',
                        help: 'Can set a image to find the template inside, or if setted to None will take a new screenshot (default=None).',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Numbers of seconds to wait until the template is displayed on screen (default=5).',
                        type: 'number',
                        value: null,
                        default: 5
                    },
                    {
                        param: 'clicks',
                        obligatory: false,
                        name: 'Number of clicks',
                        help: 'Defines how many clicks will be given (default=1).',
                        type: 'text',
                        value: null,
                        default: 1
                    },
                    {
                        param: 'move_click_delay',
                        obligatory: false,
                        name: 'Move and click delay',
                        help: 'Time in seconds between mouse move and mouse click. Usefull when using remote connections or slow applications (default=0).',
                        type: 'number',
                        value: null,
                        default: 0
                    }
                ]
            },
            {
                title: 'Double Click',
                action: 'mouse.double_click',
                params: [
                    {
                        param: 'x',
                        obligatory: false,
                        name: 'X position',
                        help: 'Number of X position on screen to click (default=None - current position).',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y',
                        obligatory: false,
                        name: 'Y position',
                        help: 'Number of Y position on screen to click (default=None - current position).',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Button to use on click',
                        type: 'text',
                        value: null,
                        default: 'left'
                    },
                    {
                        param: 'interval',
                        obligatory: false,
                        name: 'Click Interval',
                        help: 'Interval between each click, for slower computers, consider set a higher value than default (default=0 - instantaneous).',
                        type: 'number',
                        value: null,
                        default: 0
                    },
                    {
                        param: 'move_click_delay',
                        obligatory: false,
                        name: 'Move/Click Delay',
                        help: 'Delay between mouse move and click',
                        type: 'number',
                        value: null,
                        default: 0
                    }
                ]
            },
            {
                title: 'Drag to position',
                action: 'mouse.drag_to',
                params: [
                    {
                        param: 'x',
                        obligatory: true,
                        name: 'X position',
                        help: 'Number of X position go on the drag ends.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y',
                        obligatory: true,
                        name: 'Y position',
                        help: 'Number of Y position go on the drag ends.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'speed',
                        obligatory: false,
                        name: 'speed',
                        help: 'Number representing the seconds taken to move from the current position to the end position.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Defines witch mouse button should de used for drag \'left\', \'middle\' or \'right\' (default=\'left\').',
                        type: 'text',
                        value: null,
                        default: 'left'
                    },
                ]
            },
            {
                title: 'Get Current Position',
                action: 'mouse.get_position',
                params: []
            },
            {
                title: 'Click and Hold',
                action: 'mouse.mouse_down',
                params: [
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Button to use on click (\'left\', \'middle\' or \'right\') (default=\'left\').',
                        type: 'text',
                        value: null,
                        default: 'left'
                    }
                ]
            },
            {
                title: 'Click Release',
                action: 'mouse.mouse_up',
                params: [
                    {
                        param: 'button',
                        obligatory: true,
                        name: 'Button',
                        help: 'Button to release (\'left\', \'middle\' or \'right\') (default=\'left\').',
                        type: 'text',
                        value: null,
                        default: 'left'
                    },
                ]
            },
            {
                title: 'Move',
                action: 'mouse.move',
                params: [
                    {
                        param: 'x',
                        obligatory: true,
                        name: 'X position',
                        help: 'Number with how many pixels to move from the current position. X could be a positive (move left) or negative (move right) number.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y',
                        obligatory: true,
                        name: 'Y position',
                        help: 'Number with how many pixels to move from the current position. Y could be a positive (move down) or negative (move up) numb.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'speed',
                        obligatory: false,
                        name: 'Speed',
                        help: 'Number of seconds for the mouse move from current position the X, Y new position.',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Move to',
                action: 'mouse.move_to',
                params: [
                    {
                        param: 'x',
                        obligatory: false,
                        name: 'X position',
                        help: 'Number with how many pixels to move from the current position. X could be a positive (move left) or negative (move right) number.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y',
                        obligatory: false,
                        name: 'Y position',
                        help: 'Number with how many pixels to move from the current position. Y could be a positive (move down) or negative (move up) number.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'speed',
                        obligatory: false,
                        name: 'speed',
                        help: 'Number of seconds for the mouse move from current position the X, Y new position.',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Multi Click',
                action: 'mouse.multi_click',
                params: [
                    {
                        param: 'x',
                        obligatory: false,
                        name: 'X position',
                        help: 'Number of X position on screen to click (default=None - current position).',
                        type: 'number',
                        value: null,
                        default: null,
                    },
                    {
                        param: 'y',
                        obligatory: false,
                        name: 'Y position',
                        help: 'Number of Y position on screen to click (default=None - current position).',
                        type: 'number',
                        value: null,
                        default: null,
                    },
                    {
                        param: 'clicks',
                        obligatory: false,
                        name: 'Number of Clicks',
                        help: 'Number of clicks to be performed at position (default=1)',
                        type: 'number',
                        value: null,
                        default: null,
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Button to be used for multiple clicks (\'left\', \'middle\' or \'right\') (default=\'left\').',
                        type: 'text',
                        value: null,
                        default: 'left',
                    },
                    {
                        param: 'interval',
                        obligatory: false,
                        name: 'Click Interval',
                        help: 'Interval between each click, for slower computers, consider set a higher value than default (default=0 - instantaneous).',
                        type: 'number',
                        value: null,
                        default: 0
                    },
                    {
                        param: 'move_click_delay',
                        obligatory: false,
                        name: 'Move/Click Delay',
                        help: 'Delay between mouse move and click (default=0 - instantaneous).',
                        type: 'number',
                        value: null,
                        default: 0
                    }
                ]
            },
            {
                title: 'Scroll',
                action: 'mouse.scroll',
                params: [
                    {
                        param: 'size',
                        obligatory: true,
                        name: 'Scroll size',
                        help: 'Number of pixels to scroll. Negative number performs a scroll down and positive number perform a scroll up.',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'mysql.svg',
        title: 'MYSQL',
        actions: [
            {
                title: 'Close',
                action: 'mysql.close',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using mysql.open action',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Commit',
                action: 'mysql.commit',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using mysql.open action',
                        type: 'object',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Execute SQL',
                action: 'mysql.execute_sql',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using mysql.open action',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sql',
                        obligatory: true,
                        name: 'SQL',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'auto_commit',
                        obligatory: false,
                        name: 'Auto commit ',
                        help: 'Set if the auto commit on transaction should be used or not (default=True)',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Open',
                action: 'mysql.open',
                params: [
                    {
                        param: 'host',
                        obligatory: true,
                        name: 'Host',
                        help: 'IP or hostname of the server to connect.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'user',
                        obligatory: true,
                        name: 'User',
                        help: 'Username to be used on MySQL authentication.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'password',
                        obligatory: true,
                        name: 'Password',
                        help: 'Password to be used on MySQL authentication.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'database',
                        obligatory: true,
                        name: 'Database',
                        help: 'Name of the database to connect with.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'port',
                        obligatory: false,
                        name: 'Port',
                        help: 'Number of the port to connect with MySQL Server.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Rollback',
                action: 'mysql.rollback',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using mysql.open action',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Select',
                action: 'mysql.select',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using mysql.open action',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sql',
                        obligatory: true,
                        name: 'SQL',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
        ]
    },
    {
        icon: 'oracle.svg',
        title: 'Oracle',
        actions: [
            {
                title: 'Close',
                action: 'oracle.close',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using oracle.open action',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Commit',
                action: 'oracle.commit',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using oracle.open action',
                        type: 'object',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Execute SQL',
                action: 'oracle.execute_sql',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using oracle.open action',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sql',
                        obligatory: true,
                        name: 'SQL',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'auto_commit',
                        obligatory: false,
                        name: 'Auto commit ',
                        help: 'Set if the auto commit on transaction should be used or not (default=True)',
                        type: 'bool',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Open',
                action: 'oracle.open',
                params: [
                    {
                        param: 'host',
                        obligatory: true,
                        name: 'Host',
                        help: 'IP or hostname of the server to connect.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'user',
                        obligatory: true,
                        name: 'User',
                        help: 'Username to be used on Oracle authentication.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'password',
                        obligatory: true,
                        name: 'Password',
                        help: 'Password to be used on Oracle authentication.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'database',
                        obligatory: true,
                        name: 'Database',
                        help: 'Name of the database to connect with.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'port',
                        obligatory: false,
                        name: 'Port',
                        help: 'Number of the port to connect with Oracle Server.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Rollback',
                action: 'oracle.rollback',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using oracle.open action',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Select',
                action: 'oracle.select',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using oracle.open action',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sql',
                        obligatory: true,
                        name: 'SQL',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            },
        ]
    },
    {
        icon: 'pdf.svg',
        title: 'PDF',
        actions: [
            {
                title: 'Get Text',
                action: 'pdf.get_text',
                params: [
                    {
                        param: 'pdf',
                        obligatory: true,
                        name: 'PDF',
                        help: 'Can be a path to a PDF file or a PDF object already opened by pdf.open_file.',
                        // Type can be either a text or an object
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'page_num',
                        obligatory: true,
                        name: 'Page Number',
                        help: 'Defines the page number to get all texts from. Pages always starts from one.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'x1',
                        obligatory: true,
                        name: 'X1',
                        help: 'Left position of the search box',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y1',
                        obligatory: true,
                        name: 'Y1',
                        help: 'Top position of the search box',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'x2',
                        obligatory: true,
                        name: 'X2',
                        help: 'Right position of the search box',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y2',
                        obligatory: true,
                        name: 'Y2',
                        help: 'Right position of the search box',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'intersection_mode',
                        obligatory: false,
                        name: 'Intersection mode',
                        help: 'Defines how Marvin will understand if a text box is inside the search area or not. For further information read the intersection mode section: http://docs.marvin.studio/user-guide/actions/pdf/#intersection-mode',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'factor',
                        obligatory: false,
                        name: 'Factor',
                        help: 'This is the factor used by the chosen intersection_mode. For more information read the intersection mode section: http://docs.marvin.studio/user-guide/actions/pdf/#intersection-mode',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Get Text At',
                action: 'pdf.get_text_at',
                params: [
                    {
                        param: 'pdf',
                        obligatory: true,
                        name: 'PDF',
                        help: 'Can be a path to a PDF file or a PDF object already opened by pdf.///open_file.',
                        //Type can be either a text or an object
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'page_num',
                        obligatory: true,
                        name: 'Page Number',
                        help: 'Defines the page number to get all texts from. Pages always starts from one.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'x',
                        obligatory: true,
                        name: 'X coordinate',
                        help: 'Defines the page number to get all texts from. Pages always starts from one.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y',
                        obligatory: true,
                        name: 'Y coordinate',
                        help: 'Y coordinate to find a text box',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Get Text by Reference',
                action: 'pdf.get_text_by_reference',
                params: [
                    {
                        param: 'pdf',
                        obligatory: true,
                        name: 'PDF',
                        help: 'Can be a path to a PDF file or a PDF object already opened by pdf.open_file.',
                        // Type can be either a text or an object
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'page_num',
                        obligatory: true,
                        name: 'Page Number',
                        help: 'Defines the page number to get all texts from. Pages always starts from one.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'x1_offset',
                        obligatory: true,
                        name: 'X1',
                        help: 'How many pixels the search box is from the left of the reference_text (negative for left side and positive for right side)',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y1_offset',
                        obligatory: true,
                        name: 'Y1',
                        help: 'How many pixels the search box is from the top of the reference_text (negative for upper and positive for down)',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'x2_offset',
                        obligatory: true,
                        name: 'X2',
                        help: 'How many pixels the search box is from the right of the reference_text (negative for left side and positive for right side)',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'y2_offset',
                        obligatory: true,
                        name: 'Y2',
                        help: 'How many pixels the search box is from the bottom of the reference_text (negative for upper and positive for down)',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'intersection_mode',
                        obligatory: false,
                        name: 'Intersection mode',
                        help: 'Defines how Marvin will understand if a text box is inside the search area or not. For further information read the intersection mode section: http://docs.marvin.studio/user-guide/actions/pdf/#intersection-mode',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'factor',
                        obligatory: false,
                        name: 'Factor',
                        help: 'This is the factor used by the chosen intersection_mode. For more information read the intersection mode section: http://docs.marvin.studio/user-guide/actions/pdf/#intersection-mode',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Get Texts',
                action: 'pdf.get_texts',
                params: [
                    {
                        param: 'pdf',
                        obligatory: true,
                        name: 'PDF',
                        help: 'Can be a path to a PDF file or a PDF object already opened by pdf.open_file.',
                        // Type can be either a text or an object
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'page_num',
                        obligatory: true,
                        name: 'Page Number',
                        help: 'Defines the page number to get all texts from. Pages always starts from one.',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'boxes',
                        obligatory: true,
                        name: 'Boxes',
                        help: 'A list of search boxes with each search boxes having a specific process var name. Example in the documentation.',
                        type: 'list:type',
                        value: null,
                        default: null
                    },
                    {
                        param: 'intersection_mode',
                        obligatory: false,
                        name: 'Intersection mode',
                        help: 'Defines how Marvin will understand if a text box is inside the search area or not. For further information read the intersection mode section: http://docs.marvin.studio/user-guide/actions/pdf/#intersection-mode',
                        type: 'number',
                        value: null,
                        default: null
                    },
                    {
                        param: 'factor',
                        obligatory: false,
                        name: 'Factor',
                        help: 'This is the factor used by the chosen intersection_mode. For more information read the intersection mode section: http://docs.marvin.studio/user-guide/actions/pdf/#intersection-mode',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Open File',
                action: 'pdf.open_file',
                params: [
                    {
                        param: 'pdf',
                        obligatory: true,
                        name: 'PDF path',
                        help: 'path to a PDF file.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                ]
            },
        ]
    },
    {
        icon: 'prompt.svg',
        title: 'Prompt',
        actions: [
            {
                title: 'Alert',
                action: 'prompt.alert',
                params: [
                    {
                        param: 'text',
                        obligatory: true,
                        name: 'Text',
                        help: 'Text to appear for user, inside the alert screen.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'title',
                        obligatory: false,
                        name: 'Text',
                        help: 'Title of the alert screen (default=\'Alert\').',
                        type: 'text',
                        value: null,
                        default: 'Alert'
                    },
                    {
                        param: 'button',
                        obligatory: false,
                        name: 'Button',
                        help: 'Text of the button (default=\'OK\').',
                        type: 'text',
                        value: null,
                        default: 'OK'
                    },
                ]
            },
            {
                title: 'Confirm',
                action: 'prompt.confirm',
                params: [
                    {
                        param: 'text',
                        obligatory: true,
                        name: 'Text',
                        help: 'Text to appear for user, inside the alert screen.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'title',
                        obligatory: false,
                        name: 'Title',
                        help: 'Title of the alert screen (default=\'Confirm\').',
                        type: 'text',
                        value: null,
                        default: 'Confirm'
                    },
                    {
                        param: 'buttons',
                        obligatory: false,
                        name: 'Buttons',
                        help: 'List of button texts to present on screen. Example: [\'button 1\', \'button 2\', \'button 3\'].',
                        type: 'type:list',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'password',
                action: 'prompt.password',
                params: [
                    {
                        param: 'text',
                        obligatory: true,
                        name: 'Text',
                        help: 'Text to appear for user, inside the alert screen.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'title',
                        obligatory: false,
                        name: 'Title',
                        help: 'Title of the alert screen (default=\'Confirm\').',
                        type: 'text',
                        value: null,
                        default: 'Confirm'
                    },
                    {
                        param: 'default_value',
                        obligatory: false,
                        name: 'Defaut value',
                        help: 'default value to be inserted in the input area of the screen (default=\'\').',
                        type: 'text',
                        value: null,
                        default: ''
                    },
                    {
                        param: 'mask',
                        obligatory: false,
                        name: 'Input Mask',
                        help: 'Character used to mask the input value (default=\'*\')',
                        type: 'text',
                        value: null,
                        default: "*"
                    }
                ]
            },
            {
                title: 'Prompt',
                action: 'prompt.prompt',
                params: [
                    {
                        param: 'text',
                        obligatory: true,
                        name: 'Text',
                        help: 'Text to appear for user, inside the alert screen.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'title',
                        obligatory: false,
                        name: 'Title',
                        help: 'Title of the alert screen (default=\'Confirm\').',
                        type: 'text',
                        value: null,
                        default: 'Confirm'
                    },
                    {
                        param: 'default_value',
                        obligatory: false,
                        name: 'Defaut value',
                        help: 'default value to be inserted in the input area of the screen (default=\'\').',
                        type: 'text',
                        value: null,
                        default: ''
                    },
                ]
            },
        ]
    },
    {
        icon: 'screen1.png',
        title: 'Screen',
        actions: [
            {
                title: 'Find Image',
                action: 'screen.find_image',
                params: [
                    {
                        param: 'template_image',
                        obligatory: true,
                        name: 'Template image',
                        help: 'Name of the asset image used as template.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'threshold',
                        obligatory: false,
                        name: 'Threshold',
                        help: 'Minimum match to consider that the template was found (default=0.9 - 90%).',
                        type: 'float',
                        value: null,
                        default: 0.9
                    },
                    {
                        param: 'screenshot_img',
                        obligatory: false,
                        name: 'Screenshot image',
                        help: 'If the template should be found on a given screen beside on current screen (default=None - take a new screenshot).',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Find Image Multiple',
                action: 'screen.find_image_multiple',
                params: [
                    {
                        param: 'template_image',
                        obligatory: true,
                        name: 'Template image',
                        help: 'Name of the asset image used as template.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'threshold',
                        obligatory: false,
                        name: 'Threshold',
                        help: 'Minimum match to consider that the template was found (default=0.9 - 90%).',
                        type: 'float',
                        value: null,
                        default: 0.9
                    },
                    {
                        param: 'screenshot_img',
                        obligatory: false,
                        name: 'Screenshot image',
                        help: 'If the template should be found on a given screen beside on current screen (default=None - take a new screenshot).',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                    {
                        param: 'overlap_factor',
                        obligatory: false,
                        name: 'Overlap Factor',
                        help: 'Percentage of overlap to consider the same image. If the overlap between two images is higher than the overlap_factor, the two found images are considered to be the same (default=0.5 - 50%).',
                        type: 'float',
                        value: null,
                        default: 0.5
                    },
                ]
            },
            {
                title: 'Find Image Multiple Center',
                action: 'screen.find_image_multiple_center',
                params: [
                    {
                        param: 'template_image',
                        obligatory: true,
                        name: 'Template image',
                        help: 'Name of the asset image used as template.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'threshold',
                        obligatory: false,
                        name: 'Threshold',
                        help: 'Minimum match to consider that the template was found (default=0.9 - 90%).',
                        type: 'float',
                        value: null,
                        default: 0.9
                    },
                    {
                        param: 'screenshot_img',
                        obligatory: false,
                        name: 'Screenshot image',
                        help: 'If the template should be found on a given screen beside on current screen (default=None - take a new screenshot).',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                    {
                        param: 'overlap_factor',
                        obligatory: false,
                        name: 'Overlap Factor',
                        help: 'Percentage of overlap to consider the same image. If the overlap between two images is higher than the overlap_factor, the two found images are considered to be the same (default=0.5 - 50%).',
                        type: 'float',
                        value: null,
                        default: 0.5
                    },
                ]
            },
            {
                title: 'Wait Image',
                action: 'screen.wait_image',
                params: [
                    {
                        param: 'template_image',
                        obligatory: true,
                        name: 'Template image',
                        help: 'Name of the asset image used as template.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'threshold',
                        obligatory: false,
                        name: 'Threshold',
                        help: 'Minimum match to consider that the template was found (default=0.9 - 90%).',
                        type: 'float',
                        value: null,
                        default: 0.9
                    },
                    {
                        param: 'screenshot_img',
                        obligatory: false,
                        name: 'Screenshot image',
                        help: 'If the template should be found on a given screen beside on current screen (default=None - take a new screenshot).',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Maximum number os seconds to waits for a matching image. (default=60).',
                        type: 'number',
                        value: null,
                        default: 60
                    },
                ]
            },
            {
                title: 'Wait Image Disapear',
                action: 'screen.wait_image_disapear',
                params: [
                    {
                        param: 'template_image',
                        obligatory: true,
                        name: 'Template image',
                        help: 'Name of the asset image used as template.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'threshold',
                        obligatory: false,
                        name: 'Threshold',
                        help: 'Minimum match to consider that the template was found (default=0.9 - 90%).',
                        type: 'float',
                        value: null,
                        default: 0.9
                    },
                    {
                        param: 'screenshot_img',
                        obligatory: false,
                        name: 'Screenshot image',
                        help: 'If the template should be found on a given screen beside on current screen (default=None - take a new screenshot).',
                        type: 'asset',
                        value: null,
                        default: null
                    },
                    {
                        param: 'timeout',
                        obligatory: false,
                        name: 'Timeout',
                        help: 'Maximum number os seconds to waits for a matching image. (default=60).',
                        type: 'number',
                        value: null,
                        default: 60
                    },
                ]
            },
        ]
    },
    {
        icon: 'sql-server.png',
        title: 'SQL Server',
        actions: [
            {
                title: 'Close',
                action: 'sqlserver.close',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using sqlserver.open action.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Commit',
                action: 'sqlserver.commit',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using sqlserver.open action.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                ]
            },
            {
                title: 'Execute SQL',
                action: 'sqlserver.execute_sql',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using sqlserver.open action.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sql',
                        obligatory: true,
                        name: 'SQL',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'auto_commit',
                        obligatory: false,
                        name: 'Auto Commit',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: true
                    },
                ]
            },
            {
                title: 'Open',
                action: 'sqlserver.open',
                params: [
                    {
                        param: 'host',
                        obligatory: true,
                        name: 'Host',
                        help: 'IP or hostname of the server to connect.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'user',
                        obligatory: true,
                        name: 'User',
                        help: 'Username to be used on SQL authentication.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'password',
                        obligatory: true,
                        name: 'Password',
                        help: 'Password to be used on SQL authentication.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'database',
                        obligatory: true,
                        name: 'Database',
                        help: 'Name of the database to connect with.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'port',
                        obligatory: false,
                        name: 'Port',
                        help: 'Number of the port to connect with SQL Server.',
                        type: 'number',
                        value: null,
                        //Is Default port 1433?
                        default: null
                    }
                ]
            },
            {
                title: 'Rollback',
                action: 'sqlserver.rollback',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using sqlserver.open action.',
                        type: 'object',
                        value: null,
                        default: null
                    }
                ]
            },
            {
                title: 'Select',
                action: 'sqlserver.select',
                params: [
                    {
                        param: 'connection',
                        obligatory: true,
                        name: 'Connection',
                        help: 'Connection object with server. Connections are created using sqlserver.open action.',
                        type: 'object',
                        value: null,
                        default: null
                    },
                    {
                        param: 'sql',
                        obligatory: true,
                        name: 'SQL',
                        help: 'String with SQL command to be executed.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'text.svg',
        title: 'Text',
        actions: [
            {
                title: 'Remove non numeric characters',
                action: 'text.only_number',
                params: [
                    {
                        param: 'outvar',
                        obligatory: true,
                        name: 'Outvar',
                        help: 'Name of the process var to hold the new text, with only numbers.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'text',
                        obligatory: true,
                        name: 'Text',
                        help: 'Original text to have all non numeric characters removed.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'timer.svg',
        title: 'Timer',
        actions: [
            {
                title: 'Wait',
                action: 'timer.wait',
                params: [
                    {
                        param: 'seconds',
                        obligatory: true,
                        name: 'Seconds',
                        help: 'Number of seconds to wait.',
                        type: 'number',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    },
    {
        icon: 'vault.svg',
        title: 'Vault',
        actions: [
            {
                title: 'Get Password',
                action: 'vault.get_password',
                params: [
                    {
                        param: 'system',
                        obligatory: true,
                        name: 'System',
                        help: 'System defined on Windows Credential Manager.',
                        type: 'text',
                        value: null,
                        default: null
                    },
                    {
                        param: 'user',
                        obligatory: true,
                        name: 'User',
                        help: 'Username defined on Windows Credential Manager.',
                        type: 'text',
                        value: null,
                        default: null
                    }
                ]
            }
        ]
    }
]

export default actionConfig;
