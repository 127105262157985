import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
}));

const SaveModal = ({ className, open, onSave, scriptName }) => {
    
    const classes = useStyles();
    const [script, setScript] = useState(scriptName);
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (event) => {
        setScript(event.target.value);
    }

    const handleSave = (event) => {
        if (script == "") {
            setErrorMessage("Script name can't be empty")
        }
        else {
            onSave(script, null);
            handleClose();
        }
    }

    const handleClose = (event) => {
        onSave(null, 'canceled');
    }

    return (
        <Box className={clsx(classes.root, className)}>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                scroll={'paper'}
            >
                <DialogTitle>
                    Save Flow
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={ 3 }>
                        <Grid item="xs" xs={12}>
                            <TextField
                                fullWidth
                                error={Boolean(errorMessage)}
                                helperText={errorMessage ? errorMessage : "Name of script to save"}
                                label="Script name"
                                name="script"
                                onChange={(e) => {handleChange(e)}}
                                required
                                value={script}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ handleSave }
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={ handleClose }
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default SaveModal;