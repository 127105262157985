import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Card,
    CardHeader,
    Container,
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import RobotInfo from './RobotInfo';
import ActionList from '../actions/ActionList'
// import detail from './data';
import CONFIG from 'src/config/config';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const RobotDetail = ({ className, ...rest }) => {
    const classes = useStyles();
    let { robotId } = useParams();
    const [robot, setRobot] = useState({});
    const storageUser = JSON.parse(localStorage.getItem('marvinUser'));
    const [marvinUser, setMarvinUser] = React.useState(storageUser);

    const getRobot = (logedUser, robotId) => {
        console.log('updating activities');
        let result = {};
        try {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", CONFIG.API_URL + '/robots/' + robotId, false); // False = Sync
            xmlHttp.setRequestHeader('Content-Type', 'application/json');
            xmlHttp.setRequestHeader('Authorization', logedUser.token);
            xmlHttp.setRequestHeader('Access-Control-Allow-Origin', '*')
            xmlHttp.send();
    
            if (xmlHttp.status == 200) {
                result = JSON.parse(xmlHttp.response);
                console.log('activities updated');
            }
            else {
                console.error('Error updating activities => ' + xmlHttp.status);
            }
        }
        catch(error) {
            console.error('[ERROR] Error updating data from server');
            console.error(error);
        }
        return result;
    }

    React.useEffect(() => {
        let data = getRobot(marvinUser, robotId);
        setRobot(data);
    }, [])

    return (
        <Page
            className={classes.root}
            title="Robot Detail"
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                    >
                        Robot: {robot.name} [{robotId}]
                    </Typography>
                </Box>
                <Box mt={3}>
                    <RobotInfo robot={robot} />
                </Box>
                <Box mt={3}>
                    <ActionList robot={robot} />
                </Box>
            </Container>
        </Page>
    );
}

export default RobotDetail;