import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { auth } from './firebase';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/app/auth/LoginView';
import UserProvider from 'src/providers/UserProvider';
import LoadingMarvinConsole from './LoadingMarvinConsole';
import CONFIG from 'src/config/config';

const App = () => {
    const routing = useRoutes(routes);
    const storageUser = JSON.parse(localStorage.getItem('marvinUser'));
    const [marvinUser, setMarvinUser] = React.useState(storageUser);
    const [currentUser, setCurrentUser] = React.useState(auth.currentUser)

    console.log(JSON.stringify(auth.currentUser));

    const createMarvinUser = (tokenId) => {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", CONFIG.API_URL + '/admin/user', false); // False = Sync
        xmlHttp.setRequestHeader('Content-Type', 'application/json');
        xmlHttp.setRequestHeader('Authorization', tokenId);
        xmlHttp.setRequestHeader('Access-Control-Allow-Origin', '*')
        xmlHttp.send();

        let user = null;
        if (xmlHttp.status == 200) {
            user = JSON.parse(xmlHttp.response);
        }
        return user;
    }

    
    const onAuthStateChanged = (userAuth) => {
        setCurrentUser(auth.currentUser);
        if (userAuth) {
            userAuth.getIdToken().then(idToken => {
                console.log(idToken);
                if (!marvinUser || idToken != marvinUser.token) {
                    let marvinUser = createMarvinUser(idToken);
                    console.log(marvinUser);

                    if (marvinUser) {
                        setMarvinUser(marvinUser);
                        localStorage.setItem('marvinUser', JSON.stringify(marvinUser))
                    }
                    else {
                        localStorage.removeItem('marvinUser');
                        setMarvinUser(null);
                    }
                }
                else {
                    marvinUser.token = idToken;
                    setMarvinUser(marvinUser);
                }

            }).catch(e => {
                console.error(e);
                localStorage.removeItem('marvinUser');
                setMarvinUser(null);
            });
        }
        else {
            localStorage.removeItem('marvinUser');
            setMarvinUser(null);
        }
        
    }



    React.useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber;
    }, []);

    if (marvinUser) {
        if (currentUser){
            return (
                <UserProvider marvinUser={marvinUser}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyles />
                        { routing }
                    </ThemeProvider>
                </UserProvider>
            );
        }
        else {
            return (<LoadingMarvinConsole />)
        }
    }
    else {
        return (
            <UserProvider value={marvinUser}>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <MainLayout />
                    <LoginView />
                </ThemeProvider>
            </UserProvider>
        );
    }
};

export default App;
