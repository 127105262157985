import React from 'react';
import {
  	makeStyles,
} from '@material-ui/core';
import { DataSet, Network } from 'vis-network/standalone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initializeNetwork } from 'src/actions';


const useStyles = makeStyles((theme) => ({
    root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3)
	},
	procView: {
        width: '100%',
        height: '100%',
	}
}));

const ProcessCanvas = () => {
	const classes = useStyles();
	

    return (
        <div></div>
    );
};

// const mapStateToProps = store => ({
//     network: store.network
// });

// const mapDispatchToProps = dispatch => bindActionCreators({ initializeNetwork }, dispatch);

// export default connect(null, mapDispatchToProps)(ProcessCanvas);
export default ProcessCanvas;
