import React, { Component, createContext } from 'react';
import { auth } from '../firebase'
import CONFIG from 'src/config/config'

export const UserContext = createContext(null);

const UserProvider = ({children, marvinUser, ...rest}) => {
    
    return (
        <UserContext.Provider value={marvinUser}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
