import React, { useState, setValues } from 'react';
import clsx from 'clsx';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Snackbar,
    TextField,
    Typography,
    makeStyles,
    colors,
    Divider,
    InputLabel,
    Input,
    Button,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import Alert from 'src/components/Alert'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
}));

const ActionTerminate = ({ className, opens, robot, onClose, ...rest }) => {
    const classes = useStyles();
    const [snackOpen, setSnackOpen] = useState(false);

    const handleClose = (event) => {
        onClose();
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleExecuteClick = () => {
        setSnackOpen(true);
        handleClose();
    }

    return (
        <Box>
            <Dialog
                open={opens}
                fullWidth={true}
                maxWidth="xs"
                scroll={'paper'}
            >
                <DialogTitle>
                    Script Execution
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                align="center"
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Important
                            </Typography>
                            <Typography
                                align="center"
                                color="textPrimary"
                                variant="body1"
                            >
                                Executing this action, Marvin on <b>{robot.robot}</b> will be shutted down and
                                it is not possible to recover remotelly.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={handleExecuteClick}
                    >
                        Execute
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar 
                open={snackOpen} 
                autoHideDuration={6000} 
                onClose={handleCloseSnack}
            >
                <Alert severity="success">
                    Terminate sent to {robot.robot}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ActionTerminate;