import React, { useState, setValues, useEffect } from 'react';
import clsx from 'clsx';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    InputAdornment,
    TextField,
    SvgIcon,
    makeStyles,
    Divider,
} from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import RobotStatus from '../RobotStatus'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
}));

const RobotInfo = ({ className, robot, ...rest }) => {
    const classes = useStyles();
    const [robotValues, setRobotValues] = useState(robot);
    const handleChange = (event) => {
        setRobotValues({
            ...robotValues,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        setRobotValues(robot);
    }, [robot]);

    return (
        <Card
            className={clsx(classes.root, className)}
        >
            <CardHeader
                subheader="The information can be edited"
                title="Robot"
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            helperText="Robot Name"
                            label="Robot Name"
                            name="robot"
                            onChange={handleChange}
                            required
                            disabled
                            value={robotValues.name}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Computer Name"
                            name="computerName"
                            onChange={handleChange}
                            disabled
                            value={robotValues.computerName}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Status"
                            name="status"
                            onChange={handleChange}
                            disabled
                            value={robot.robotStatus}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <RobotStatus status={robot.robotStatus} />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default RobotInfo;